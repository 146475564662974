import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import { toast } from "../../../state/snackbar";
import { LoadingButton } from "@mui/lab";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { user$ } from "../../../state/user";
import { WritableCell } from "../components/writeable.cell";
import { ICreateEntity } from "../_.types";

// TODO: RenderCell
// TODO: understand <T,> ????
export const CreateEntity = <T,>({
  fieldDelineations,
  onClose,
  initialData,
  createEntity,
  appendMemberDetailsToEntity = true,
}: ICreateEntity<T>) => {
  const sectionNum = fieldDelineations.length - 1;
  const theme = useTheme();
  const user = useBehaviourSubject(user$);
  const [entity, setEntityData] = React.useState<T>({ ...initialData });
  // const [entity, setEntity] = React.useState<T>({ ...doc });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (
    e: string | number | string[] | number[],
    field: string
  ) => {
    setEntityData({ ...entity, [field]: e });
  };
  const handleChangeObj = (newEntity: T) => {
    setEntityData({
      ...entity,
      ...newEntity,
    });
  };

  const handleSubmit = () => {
    if (user) {
      setLoading(true);
      createEntity(entity)
        .then(() => {
          toast("Entity Created Successfully", "success");
          onClose();
        })
        .catch(() => {
          toast("Error happened while creating the entity", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (user && appendMemberDetailsToEntity) {
      setEntityData((prevData) => ({
        ...prevData,
        member_id: user.member_id,
        member_name: user.member_name,
      }));
    }
  }, [user]);

  return (
    <Fragment>
      <Grid container justifyContent={"space-between"} sx={{ minHeight: 700 }}>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ height: 670, overflowY: "auto" }}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {fieldDelineations.map((section, i) => (
              <Step key={i}>
                <StepLabel onClick={() => setActiveStep(i)}>
                  {section.section}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1}>
                    {section.fields.map((fieldProps, i) => (
                      <WritableCell
                        key={i}
                        entity={entity}
                        handleChange={handleChange}
                        handleChangeObj={handleChangeObj}
                        {...fieldProps}
                      />
                    ))}
                  </Grid>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"end"}
          spacing={2}
        >
          <Grid item>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={activeStep === sectionNum && loading}
              onClick={activeStep === sectionNum ? handleSubmit : handleNext}
            >
              {activeStep === sectionNum ? "Submit" : "Next"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
