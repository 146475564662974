import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  IMember,
  IMemberEditRequest,
  IMemberRequest,
  IMembershipChildEditRequest,
  IMembershipChildRequest,
  IRequestRecordKey,
} from "../../module.interface";
import { toast } from "../../../state/snackbar";
import { LoadingButton } from "@mui/lab";
import _, { get, isNumber } from "lodash";
import { FieldCard } from "../components/field-card";
import {
  CreateRequestRecord,
  GetRequestRecordByKey,
  UpdateRequestRecord,
} from "../../../services/request.service";
import isEmail from "validator/lib/isEmail";
import { WritableCell } from "../../_/components/writeable.cell";
import { CreateMembershipChildRequestRecord } from "../../../services/membership.service";

type IMembershipDetails = IMember & {
  _id: string;
};
type IProps = {
  loading: boolean;
  member: IMemberEditRequest;
};

export const MembershipEditRequest: React.FC<IProps> = ({
  loading,
  member: m,
}) => {
  const theme = useTheme();
  const [color, setColor] = React.useState<
    "primary" | "secondary" | "info" | "error" | "success" | "warning"
  >("primary");

  const [member, setMember] = React.useState<IMemberEditRequest>(m);
  const [oldMember, setOldMember] = React.useState<IMemberEditRequest>(m);
  const [childsIds, setChildsIds] = React.useState<string[] | number[]>();
  // const [previousEditRequest, setPreviousEditRequest] =
  // React.useState<IRequestRecord>();
  const [requestedMember, setRequestedMember] =
    React.useState<IMemberEditRequest>();
  const [requestedChild, setRequestedChild] =
    React.useState<IMembershipChildEditRequest[]>();

  // Edit Phase
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);

  const handleClickEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleChange = (e: any, field: string) => {
    const x = { ...member };
    switch (field) {
      case "member_name":
        x.member_name = e.target.value;
        break;
      case "email":
        x.email = e.target.value;
        break;
      case "is_parent":
        x.is_parent = e.target.checked;
        break;
      case "childs_ids":
        x.childs_ids = e;
        break;
    }
    setMember(x as IMemberEditRequest);
  };

  const handleSubmit = () => {
    setSubmitCounter(true);
    if (
      member === undefined ||
      member.email === undefined ||
      member.member_name === undefined ||
      oldMember === undefined ||
      oldMember.email === undefined ||
      oldMember.member_name === undefined
    ) {
      toast("Name or Email is empty", "error");
      return;
    }
    const value: IMemberEditRequest = {
      is_parent: member.is_parent,
      member_name: member.member_name,
      email: member.email,
      childs_ids: member.childs_ids,
    };
    const old_value: IMemberEditRequest = {
      is_parent: member.is_parent,
      member_name: oldMember.member_name,
      email: oldMember.email,
      childs_ids: member.childs_ids,
    };
    const requestRecord: IMemberRequest = {
      action: "Update",
      module: "membership",
      field: "",
      value: value,
      old_value: old_value,
      created_by: member.email,
    };

    if (requestedMember && requestedMember._id) {
      const updateRequestRecordProms = {
        ...requestRecord,
        _id: requestedMember._id,
      };
      UpdateRequestRecord(updateRequestRecordProms)
        .then(
          (res) => (
            toast("Request updated successfully", "success"), console.log(res)
          )
        )
        .catch(
          (error) => (
            console.log(error),
            toast("Error happened while updating the request", "error")
          )
        )
        .finally(() => {
          setSubmitCounter(false);
          setIsEdit(false);
        });
    } else {
      CreateRequestRecord(requestRecord)
        .then((res) => {
          toast("Request created successfully", "success");
          setRequestedMember(member);
          setMember(oldMember);
        })
        .catch(() => {
          toast("Error happened while creating the request", "error");
        })
        .finally(() => {
          setSubmitCounter(false);
          setIsEdit(false);
        });
    }
  };

  const getPreviousEditRequestByKey = () => {
    if (m === undefined || m.email === undefined) {
      toast("You do not have a member email, please set it first");
      return;
    }
    const requestKey: IRequestRecordKey = {
      module: "membership",
      action: "Update",
      created_by: m.email,
    };
    GetRequestRecordByKey(requestKey)
      .then((req) => {
        setRequestedMember({ ...req.data[0].value, _id: req.data[0]._id });
      })
      .catch((error) => {
        console.log(error);
        toast("Error happened while getting the previous request", "error");
      });
  };

  const getPreviousChildRequestByKey = () => {
    if (m === undefined || m.email === undefined) {
      toast("You do not have a member email, please set it first");
      return;
    }
    const requestKey: IRequestRecordKey = {
      module: "membership",
      action: "Create",
      created_by: m.email,
    };
    GetRequestRecordByKey(requestKey)
      .then((req) => {
        const membershipChildRequests = req.data as IMembershipChildRequest[];
        const childRequests = membershipChildRequests.map((childRequest) => ({
          ...childRequest.value,
          _id: childRequest._id,
        }));
        setRequestedChild(childRequests);
      })
      .catch((error) => {
        toast(
          "Error happened while getting the previous child request",
          "error"
        );
      });
  };

  useEffect(() => {
    setColor(isEdit ? "success" : "primary");
  }, [isEdit]);
  useEffect(() => {
    getPreviousChildRequestByKey();
    getPreviousEditRequestByKey();
    setMember(m);
    setOldMember(m);
  }, [m]);
  useDeepCompareEffect(() => {
    if (!_.isEqual(member, oldMember)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [member, oldMember]);

  return (
    <>
      <Grid item lg={8} md={8} sm={8} xs={8} sx={{ mt: 5 }}>
        {/* Subtitle */}
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            variant="h6"
            sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
          >
            Critical Information
          </Typography>
          {/* Buttons */}
          {isEdit && (
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    startIcon={<Icon>history</Icon>}
                    onClick={() => {
                      setMember(oldMember as IMemberEditRequest);
                    }}
                    disabled={!hasChanged || submitCounter}
                  >
                    Revert
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!hasChanged}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            {!isEdit ? (
              <LoadingButton
                loading={submitCounter}
                color="primary"
                size="small"
                variant="contained"
                startIcon={<Icon>edit</Icon>}
                onClick={handleClickEdit}
              >
                Edit
              </LoadingButton>
            ) : (
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                startIcon={<Icon>close</Icon>}
                onClick={() => {
                  setMember(oldMember as IMemberEditRequest);
                  setIsEdit(false);
                }}
              >
                Close
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ mt: 1 }} />
      </Grid>

      {/* Name */}
      <Grid item lg={8} md={8} sm={8} xs={8}>
        <FieldCard
          title="Name"
          isDefault
          loading={loading}
          isEdit={isEdit}
          value={member?.member_name}
          requested={requestedMember?.member_name}
          onChange={(e) => {
            return handleChange(e, "member_name");
          }}
        />
        {isEdit && requestedMember && (
          <Typography variant="caption">
            Requested: {requestedMember.member_name}
          </Typography>
        )}
      </Grid>

      <Grid item lg={8} md={8} sm={8} xs={8}>
        {/* Email */}
        <FieldCard
          title="Email"
          isDefault
          loading={loading}
          isEdit={isEdit}
          value={member?.email}
          requested={requestedMember?.email}
          onChange={(e) => {
            return handleChange(e, "email");
          }}
          error={
            !_.isEmpty(member?.email) ? !isEmail(member?.email) : undefined
          }
        />
        {isEdit && requestedMember && (
          <Typography variant="caption">
            Requested: {requestedMember.email}
          </Typography>
        )}
      </Grid>

      {/* Children */}
      {/* Request To Add Multiple Children */}
      {/* TODO: children */}
      {oldMember?.is_parent &&
        oldMember.childs_ids &&
        oldMember.childs_ids.length > 0 && (
          <>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Paper
                elevation={2}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.default,
                  padding: 2,
                }}
              >
                <WritableCell
                  entity={member}
                  handleChange={handleChange}
                  masterColumnName="childs_ids"
                  masterColumnTitle="Children"
                  renderType="text-array"
                  immutable={!isEdit}
                  selectable={{
                    array: oldMember?.childs_ids.map((c) => c.toString()) || [],
                    multiSelect: true,
                    AddSelectable: {
                      fieldDelineations: [
                        {
                          section: "Info",
                          fields: [
                            {
                              masterColumnName: "member_name",
                              masterColumnTitle: "Name",
                              renderType: "text",
                            },
                            {
                              masterColumnName: "email",
                              masterColumnTitle: "Email",
                              renderType: "text",
                            },
                            {
                              masterColumnName: "created_by",
                              masterColumnTitle: "Created By",
                              renderType: "text",
                              immutable: true,
                            },
                          ],
                        },
                      ],
                      createEntity: CreateMembershipChildRequestRecord,
                      module_permission: "inventory",
                      initialData: { created_by: m.email },
                      titleModal: "Request New Child Membership",
                      subTitleModal: "Send us a new child membership request",
                    },
                  }}
                />
              </Paper>
            </Grid>

            <Grid item lg={8} md={8} sm={8} xs={8}>
              {/* Subtitle */}
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
                >
                  Requested Children: <u>{requestedChild?.length}</u>
                </Typography>
              </Grid>
              <Divider sx={{ mt: 1 }} />
            </Grid>

            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Box
                sx={{
                  width: "full",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {requestedChild?.map((child, index) => {
                  return (
                    <Paper
                      key={index}
                      elevation={2}
                      sx={{
                        width: "100%",
                        backgroundColor: theme.palette.background.default,
                        padding: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "full",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Stack width={"100%"}>
                          <Typography
                            color={color}
                            sx={{
                              fontSize: 14,
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Name
                          </Typography>
                          <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                            {child.member_name}
                          </Typography>
                        </Stack>
                        <Stack width={"100%"}>
                          <Typography
                            color={color}
                            sx={{
                              fontSize: 14,
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            email
                          </Typography>
                          <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                            {child.email}
                          </Typography>
                        </Stack>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
            </Grid>
          </>
        )}
    </>
  );
};
