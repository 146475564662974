import { snakeCase, toLower } from "lodash";

export const GetCarPartImageName = (car_part_name: string): string => {
  const lowercased_name = toLower(car_part_name);
  const ac_replaced = lowercased_name.replaceAll("a/c", "ac");
  let brackets_removed = ac_replaced;
  if (brackets_removed.includes("(")) {
    brackets_removed = brackets_removed.split("(")[0].trim();
  }

  if (brackets_removed.includes("/")) {
    const dash_removed = brackets_removed.split("/")[0].trim();
    const form_public = `/images/car_parts/${snakeCase(dash_removed)}.jpeg`;
    return form_public;
  } else {
    const form_public = `/images/car_parts/${snakeCase(brackets_removed)}.jpeg`;
    return form_public;
  }
};
