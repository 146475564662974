import { Box } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { IESDoc } from "../../../../app.interface";
import { AppAssetsSectionBox } from "../../../../views/app.section.view";
import { AppDocView } from "../../../../views/app.doc.view";
import "../../../module.styles.css";

export const UsersAdvancedView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [views] = React.useState<string[]>(["Details"]);
  const [selectedView] = React.useState(views[0]);

  return (
    <Fragment>
      {/* <Grid container justifyContent="center" style={{ margin: "16px 0" }}>
        <Grid item>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {views.map((x) => (
              <Button
                key={x}
                size={"small"}
                variant={selectedView === x ? "contained" : "outlined"}
                onClick={() => setSelectedView(x)}
              >
                {x}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid> */}
      <Box>
        <Fragment>
          <AppAssetsSectionBox title={_.startCase(selectedView)} type={doc._id}>
            <AppDocView
              doc={doc}
              colDefs={[
                {
                  masterColumnName: "staff_name",
                  masterColumnTitle: "Employee name",
                  renderType: "text",
                },
                {
                  masterColumnName: "staff_email",
                  masterColumnTitle: "Email",
                  renderType: "text",
                },
                {
                  masterColumnName: "is_disabled",
                  masterColumnTitle: "Disabled",
                  renderType: "boolean",
                },
                {
                  masterColumnName: "role_name",
                  masterColumnTitle: "Employee Role",
                  renderType: "text",
                },

                {
                  masterColumnName: "created_at",
                  masterColumnTitle: "Created On",
                  renderType: "date_time",
                },
              ]}
            />
          </AppAssetsSectionBox>
        </Fragment>
      </Box>
    </Fragment>
  );
};
