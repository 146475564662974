import { Box, Link } from "@mui/material";
import React from "react";
import { Fragment } from "react";

export const UrlRenderer: React.FC<{ url: string; multiline: boolean }> = ({
  url,
  multiline,
}) => {
  return (
    <Fragment>
      <Box className={`${multiline ? "multiline" : "single-line"}-url-wrapper`}>
        <Link
          className="url-render-subject"
          href={
            url.startsWith("http://") || url.startsWith("https://")
              ? url
              : `http://${url}`
          }
          target="_blank"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {url}
        </Link>
      </Box>
    </Fragment>
  );
};
