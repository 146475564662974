import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Stack,
  TextField,
} from "@mui/material";
import React, { Fragment } from "react";
import { FetchLoginCredentials } from "../services/auth.service";
import { toast } from "../state/snackbar";
import { has } from "lodash";
import { ValidateEmail } from "../util/validator";

interface ILoginForm {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginForm: React.FC<ILoginForm> = ({ setIsLogin }) => {
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [userPassword, setUserPassword] = React.useState<string>("");
  const [keepLoggedIn, setKeepLoggedIn] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchLoginCredentials = () => {
    setLoading(true);
    FetchLoginCredentials(userEmail, userPassword)
      .then((res) => {
        //save username and email as md5
        // save username
        // save email
        if (res.data.length === 1) {
          //user found
          sessionStorage.setItem(
            "logged_user_record",
            JSON.stringify(res.data[0])
          );
          if (keepLoggedIn) {
            localStorage.setItem(
              "logged_user_record",
              JSON.stringify(res.data[0])
            );
          }
          window.location.replace("/");
        } else if (res.data.length > 1) {
          // duplicated user !
          toast(
            "Your Email And/Or password are duplicated, please check your system admin",
            "warning"
          );
          setUserEmail("");
          setUserPassword("");
          setKeepLoggedIn(false);
        } else {
          // no user found
          toast(
            "Your Email And/Or password are not found, please check your login credentials",
            "error"
          );
          setUserEmail("");
          setUserPassword("");
          setKeepLoggedIn(false);
        }

        // localStorage.setItem("logged_user_record-token", tokens.token);
      })
      .catch(() => {
        toast("Error happened while fetching login credentials", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const savedUserToken = JSON.parse(
      localStorage.getItem("logged_user_record") || "{}"
    );
    const tempUserToken = JSON.parse(
      sessionStorage.getItem("logged_user_record") || "{}"
    );

    if (
      has(savedUserToken, "staff_email") ||
      has(tempUserToken, "staff_email")
    ) {
      window.location.replace("/");
    }
  }, []);

  return (
    <Fragment>
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <TextField
            size="small"
            value={userEmail}
            label="Email"
            type="email"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            fullWidth
            sx={{ minWidth: "100%", fontSize: 13, fontWeight: 600 }}
            error={userEmail.length > 0 ? !ValidateEmail(userEmail) : undefined}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <Icon color="primary">email</Icon>
                </React.Fragment>
              ),
            }}
            onKeyDown={(ev) =>
              ev.key === "Enter" && userEmail !== "" && userPassword !== ""
                ? fetchLoginCredentials()
                : null
            }
          />
          <TextField
            size="small"
            value={userPassword}
            label="Password"
            type="password"
            onChange={(e) => setUserPassword(e.target.value)}
            fullWidth
            sx={{ minWidth: "100%", fontSize: 13, fontWeight: 600 }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <Icon color="primary">password</Icon>
                </React.Fragment>
              ),
            }}
            onKeyDown={(ev) =>
              ev.key === "Enter" && userEmail !== "" && userPassword !== ""
                ? fetchLoginCredentials()
                : null
            }
          />

          <Grid container justifyContent={"flex-start"}>
            <Grid item>
              <FormControlLabel
                label="Keep me logged in"
                control={
                  <Checkbox
                    checked={keepLoggedIn}
                    onChange={(e) => {
                      setKeepLoggedIn(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid container justifyContent={"space-between"} rowSpacing={1}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              sx={{ paddingLeft: "0px !important", mb: 6 }}
            >
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  fetchLoginCredentials();
                }}
                disabled={userEmail === "" || userPassword === ""}
                sx={{ minWidth: "100%" }}
              >
                Login
              </LoadingButton>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignSelf={"center"}
              sx={{ paddingLeft: "0px !important", textAlign: "center" }}
            >
              <Button
                color="secondary"
                size="medium"
                onClick={() => {
                  setIsLogin(false);
                }}
              >
                Request an Account
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Fragment>
  );
};
