import { Grid, Chip, Avatar } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { IFilterView } from "../module.interface";

export const AppFilterView: React.FC<IFilterView> = ({
  filters,
  publishFilters,
}) => {
  const returnValue = (field: string, value: any): string => {
    switch (field) {
      default:
        if (field.includes("email")) {
          return value;
        } else {
          return _.startCase(value);
        }
    }
  };
  const formatIsBetweenValue = (
    value: string | number,
    field: string
  ): string => {
    switch (field) {
      case "@timestamp":
        return moment(parseInt(value.toString())).format("lll");
      default:
        return value.toString();
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} alignItems="center">
        {filters.map((f) => (
          <Grid item key={f.id}>
            <Chip
              className={
                f.operator === "is not" ||
                f.operator === "is not one of" ||
                f.operator === "does not exists"
                  ? "exclude-chip"
                  : "include-chip"
              }
              color="primary"
              size="small"
              label={
                _.includes(
                  ["is", "is not", "is one of", "is not one of"],
                  f.operator
                )
                  ? `${f.title} : ${returnValue(f.field, f.value)}`
                  : _.includes(["exists", "does not exists"], f.operator)
                  ? `${f.title}`
                  : f.operator === "lt"
                  ? `${f.title} < ${f.value[0]}`
                  : f.operator === "gt"
                  ? `${f.title} > ${f.value[0]}`
                  : f.operator === "lte"
                  ? `${f.title} <= ${f.value[0]}`
                  : f.operator === "gte"
                  ? `${f.title} >= ${f.value[0]}`
                  : f.operator === "is between"
                  ? `${f.title} : ${formatIsBetweenValue(
                      f.value[0],
                      f.field
                    )} to ${formatIsBetweenValue(f.value[1], f.field)}`
                  : `${f.title} : ${returnValue(f.field, f.value)}`
              }
              onDelete={() =>
                publishFilters(filters.filter((x) => x.id !== f.id))
              }
              avatar={<Avatar>{f.field[0].toUpperCase()}</Avatar>}
            />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
