import React, { useEffect, Fragment } from "react";
import { $snackBar } from "../state/snackbar";
import { useSnackbar } from "notistack";
// interface SnackbarProps {
//   severity?: string;
//   message?: string;
// }

export const Snackbar: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackbarUpdate = ({ message, severity }: any) => {
    if (message) {
      enqueueSnackbar(message, {
        variant: severity || "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  useEffect(() => {
    const subscription = $snackBar.subscribe(handleSnackbarUpdate);

    return () => {
      // Unsubscribe on component unmount
      subscription.unsubscribe();
    };
  }, [enqueueSnackbar]);

  return <Fragment />;
};
