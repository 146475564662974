import { ReactNode } from "react";
import {
  RenderArray,
  RenderBoolean,
  RenderDate,
  RenderDateTime,
  RenderImage,
  RenderNumber,
  RenderText,
  RenderTime,
  RenderTitle,
  RenderUrl,
  RenderPartsView,
} from "./cell-renderer/renderers";

export interface ICommunicationEmail {
  email: string;
  contact_name: string;
  is_automated: boolean;
}

export interface ICommunicationForm {
  form_url?: string;
  is_automated: boolean;
}

export const RenderCell = (
  value: string | number | boolean | string[] | number[] | null | undefined,
  renderType: string
): ReactNode => {
  switch (renderType) {
    case "number":
      return RenderNumber(value as number);
    case "text":
      return RenderText(value as string);
    case "image":
      return RenderImage(value as string);
    case "text_toolTipped":
      return RenderText(value as string, true);
    case "title":
      return RenderTitle(value as string);
    case "time":
      return RenderTime(value as string);
    case "date":
      return RenderDate(value as string);
    case "url":
      return RenderUrl(value as string);
    case "boolean":
      return RenderBoolean(value as string);
    case "parts_view":
      return RenderPartsView(value as any);

    case "date_time":
      return RenderDateTime(value as string);
    case "array":
      return RenderArray(value as string[] | number[], "none");
    case "array_inline":
      return RenderArray(value as string[] | number[], "none", undefined, true);
    case "url_array":
      return RenderArray(value as string[] | number[], "url");
    case "url_array_max_block":
      return RenderArray(value as string[] | number[], "url", 2);
    case "tags_array_max_block":
      return RenderArray(value as string[] | number[], "tags", 2);
    case "title_array_max_block":
      return RenderArray(value as string[] | number[], "title", 2);
    case "array_max_block":
      return RenderArray(value as string[] | number[], "none", 2);
    case "tags":
      return RenderArray(value as string[] | number[], "tags");
    case "chips":
      return RenderArray(value as string[] | number[], "tags");

    default:
      return RenderText(value as string);
  }
};
