import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import React, { Fragment } from "react";
import { logout } from "../authentication/logout";

export const LogOffModel: React.FC<{
  user: any;
  onClose: () => void;
}> = ({ user, onClose }) => {
  const userLogout = async () => {
    logout();
    onClose();
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Exit Scrap</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">
            Are you sure you want to Logout ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={userLogout}>Logout</Button>
          <Button onClick={onClose} variant="contained" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
