import axios from "./axios.service";

export const FetchLoginCredentials = async (
  email: string,
  password: string
): Promise<any> => {
  try {
    const response = await axios.post("/authentication/login", {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const SubmitResetPassword = async (
  oldPass: string,
  newPass: string
): Promise<any> => {
  try {
    const response = await axios.post("/authentication/reset-pass", {
      oldPass,
      newPass,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
