import React, { Fragment } from "react";
import { StyledPaper } from "../../components/styled-paper";
import { StyledBarChart } from "../../../charts-library/bar-chart";
import { Typography, useTheme } from "@mui/material";
import { GetTopEmployeeInventory } from "../../../services/dashboard.service";
import { toast } from "../../../state/snackbar";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { selectedMember$, user$ } from "../../../state/user";
import { IBucket } from "../../module.interface";
import take from "lodash/take";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { SimplifiedTable } from "../../../components/simplified-table";
import { UUIDGenerator } from "../../../util/uuid.generator";
import { IESDoc } from "../../../app.interface";
import useDeepCompareEffect from "use-deep-compare-effect";

export const TopEmployeesBarChart: React.FC = () => {
  const selectedMember = useBehaviourSubject(selectedMember$);
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IESDoc[]>([]);
  const [chartData, setChartData] = React.useState<IBucket[]>([]);

  const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const user = useBehaviourSubject(user$);
  const fetchData = () => {
    if (user) {
      setLoading(true);
      GetTopEmployeeInventory(user.member_id)
        .then((res) => {
          setData(
            res.data.map((i: any) => {
              return {
                key: i.key,
                doc_count: i.doc_count,
                _id: UUIDGenerator(),
              };
            })
          );
          setChartData(take(res.data, 7));
        })
        .catch(() => {
          toast("Erorr happened while fetching top employees", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useDeepCompareEffect(fetchData, [user, selectedMember]);
  return (
    <Fragment>
      <StyledPaper
        title="Top Employees"
        subtitle="Overview of your employees performance"
        expandDetails={true}
        onExpand={() => {
          setIsDetailsOpen(true);
        }}
      >
        <StyledBarChart
          chartColor={theme.palette.secondary.main}
          labels={chartData.map((i) => i.key)}
          chartSeries={[
            {
              name: "Cars Added",
              data: chartData.map((i) => i.doc_count),
            },
          ]}
          loading={loading}
        />
      </StyledPaper>

      {isDetailsOpen ? (
        <FullScreenModal
          isOpen={isDetailsOpen}
          onClose={() => {
            setIsDetailsOpen(false);
          }}
          width="md"
          title="Employees Inventory List"
          subTitle="List of how many cars added to your inventory by your employees"
          content={
            <SimplifiedTable
              loading={loading}
              docs={data}
              colDefs={[
                {
                  masterColumnName: "doc_count",
                  masterColumnTitle: "Employee Name",
                  renderType: "text",
                  cellRender: (params) => <Typography>{params.key}</Typography>,
                },
                {
                  masterColumnName: "doc_count",
                  masterColumnTitle: "Cars Added",
                  renderType: "text",
                },
              ]}
            />
          }
        />
      ) : null}
    </Fragment>
  );
};
