import {
  IconButton,
  Icon,
  Popover,
  Box,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import React, { Fragment } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lottie/profile.json";
import { Accessibility } from "./accessibility/accessibility";
import { PreBuildThemes } from "./appearance/themes.prebuilt";
import { useBehaviourSubject, useObservable } from "../hooks/user-obsevrable";
import { UserPreferences } from "./user.preferences";
import "./styles.css";
import { user$ } from "../state/user";

export const AppUserProfile: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const userSelectedTheme = useObservable(
    UserPreferences.local("selected_theme")
  );
  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);
  return (
    <Fragment>
      {user ? (
        <Fragment>
          <Tooltip title="Profile" placement="bottom">
            <IconButton
              aria-label="account_circle"
              onClick={(e) => setUserMenuEl(e.currentTarget)}
              size="large"
            >
              <Icon>account_circle</Icon>
            </IconButton>
          </Tooltip>

          <Popover
            open={Boolean(userMenuEl)}
            anchorEl={userMenuEl}
            onClose={() => setUserMenuEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box className="user-popover-container">
              <Box className="user-profile-header">
                <Box className="image">
                  <Lottie
                    options={{
                      animationData: animationData,
                      loop: true,
                      autoplay: true,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    width={60}
                    height={60}
                  />
                </Box>
                <Box className="user-basic-info">
                  <Typography
                    variant="h5"
                    className="user-name"
                    color="primary"
                  >
                    {user?.staff_name}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="user-email"
                    color="textPrimary"
                    fontWeight={600}
                  >
                    {user?.staff_email}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box className="user-control-panel">
                <PreBuildThemes theme={userSelectedTheme} />
              </Box>
              <Box className="user-control-panel">
                <Accessibility onClose={() => setUserMenuEl(null)} />
              </Box>
            </Box>
          </Popover>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
