import {
  Box,
  Dialog,
  Grid,
  Icon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/password.json";
import { LoadingButton } from "@mui/lab";
import venLogo from "../../assets/logo/venestusy-logo.png";
import { toast } from "../../state/snackbar";
import { SubmitResetPassword } from "../../services/auth.service";
interface IResetPassModel {
  onClose: () => void;
  isOpen: boolean;
}

export const ResetPassModel: React.FC<IResetPassModel> = ({
  onClose,
  isOpen,
}) => {
  const [oldPass, setOldPass] = React.useState<string>("");
  const [newPass, setNewPass] = React.useState<string>("");
  const [confirmPass, setConfirmPass] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const validatePassReset = () => {
    if (oldPass !== "" && newPass !== "" && confirmPass !== "") {
      setLoading(true);
      SubmitResetPassword(oldPass, newPass)
        .then(() => {
          toast("Your password has been changed successfully", "success");
          onClose();
        })
        .catch(() => {
          toast("Your old password is incorrect", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Fragment>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="modal-container"
        aria-describedby="full-screen-modal-container"
      >
        <Grid container>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              backgroundImage: "linear-gradient(to right, #0C1339, #000000)",
            }}
          >
            <Box>
              <Lottie
                options={{
                  animationData: animationData,
                  loop: true,
                  autoplay: true,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={500}
                height={500}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Stack spacing={3} sx={{ padding: 4 }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    textAlign: "center",
                    paddingTop: 3,
                  }}
                  color="primary"
                >
                  Change Your Password
                </Typography>

                <TextField
                  fullWidth
                  size="small"
                  value={oldPass}
                  onChange={(e) => {
                    setOldPass(e.target.value);
                  }}
                  label="Old Password"
                  type="password"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Icon color="primary">lock</Icon>
                      </React.Fragment>
                    ),
                  }}
                  helperText="Contact the Admin if you forgot your old password"
                />
                <TextField
                  fullWidth
                  size="small"
                  value={newPass}
                  onChange={(e) => {
                    setNewPass(e.target.value);
                  }}
                  label="New Password"
                  type="password"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Icon color="primary">password</Icon>
                      </React.Fragment>
                    ),
                  }}
                  helperText="Enter the new password"
                />
                <TextField
                  fullWidth
                  size="small"
                  value={confirmPass}
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                  label="Confirm Password"
                  type="password"
                  error={
                    newPass === "" || confirmPass === ""
                      ? undefined
                      : newPass !== confirmPass
                  }
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Icon
                          color={
                            newPass === "" || confirmPass === ""
                              ? "primary"
                              : newPass === confirmPass
                              ? "success"
                              : "error"
                          }
                        >
                          {newPass === "" || confirmPass === ""
                            ? "password"
                            : newPass === confirmPass
                            ? "check"
                            : "clear"}
                        </Icon>
                      </React.Fragment>
                    ),
                  }}
                  helperText="Confirm the new password entered"
                />
                <Box sx={{ mt: 2 }}></Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={
                    newPass === "" ||
                    confirmPass === "" ||
                    newPass !== confirmPass
                  }
                  loading={loading}
                  onClick={() => {
                    validatePassReset();
                  }}
                >
                  Verify
                </LoadingButton>
              </Stack>
              <Box sx={{ position: "absolute", right: 10, bottom: 0 }}>
                <Stack direction={"row"}>
                  <img
                    src={venLogo}
                    alt="Venustusy Logo"
                    height={40}
                    width={40}
                    style={{ alignSelf: "center" }}
                  />
                  <Typography
                    sx={{ fontWeight: 500, alignSelf: "center", ml: -1.2 }}
                  >
                    <em>Ven</em>
                    <span style={{ fontWeight: 700, color: "#0C1339" }}>
                      Auth
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
