import { IESQueryResponse } from "../app.interface";
import axios from "./axios.service";

export const FetchMemberData = async <T>(
  member_id: number
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/members/get", {
      member_id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchAllowedMemberData = async <T>(): Promise<
  IESQueryResponse<T>
> => {
  try {
    const response = await axios.post("/members/get-allowed");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
