import React, { Fragment, useEffect } from "react";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import { user$ } from "../../state/user";
import { Grid } from "@mui/material";
import { TestEmailFunctionality } from "../../services/admin.service";
import { toast } from "../../state/snackbar";
import { LoadingButton } from "@mui/lab";
import { Navigate, Outlet } from "react-router-dom";

export const InternalRoute: React.FC = () => {
  const user = useBehaviourSubject(user$);

  return user && user.is_internal ? <Outlet /> : <Navigate to="/" replace />;
};
export const AdminPanel: React.FC = () => {
  const user = useBehaviourSubject(user$);

  useEffect(() => {
    if (user && user.staff_email !== "m.waleed65@gmail.com") {
      window.open("/", "_self");
    }
  }, [user]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const testEmail = () => {
    setLoading(true);
    TestEmailFunctionality()
      .then(() => {
        toast("Sent Successfully", "success");
      })
      .catch(() => {
        toast("Not Sent !!!", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item>
          <LoadingButton
            loading={loading}
            onClick={() => {
              testEmail();
            }}
          >
            test email
          </LoadingButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};
