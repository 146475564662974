import {
  Box,
  Chip,
  Collapse,
  Drawer,
  Grid,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import "./styles.css";
import { IAppModules } from "../modules/module.interface";
import _ from "lodash";
import { useLocation, Link } from "react-router-dom";
import { receiveModules } from "../modules/module.register";
import vLogo from "../assets/logo/venustusy-logo-full.png";

export const SideBar: React.FC = () => {
  const theme = useTheme();
  const [modules] = React.useState<IAppModules[]>(receiveModules());

  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState<string>(
    window.location.pathname
  );
  const [activeMenus, setActiveMenus] = React.useState<string[]>([
    modules[0]?.name,
  ]);
  const onClickMasterModule = (module_name: string) => {
    if (_.includes(activeMenus, module_name)) {
      setActiveMenus((arr) => arr.filter((f) => f !== module_name));
    } else {
      setActiveMenus((arr) => [...arr, module_name]);
    }
  };

  const pathMapping = (module_name: string): string => {
    return `/${module_name}`;
  };

  const isModuleSelected = (module_name: string): boolean => {
    if (currentPath.startsWith(pathMapping(module_name))) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const path = currentPath.split("/")[1];
    setActiveMenus([path]);
  }, [currentPath]);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <Fragment>
      <Box>
        <Drawer
          open
          variant="permanent"
          sx={{
            backgroundColor: theme.palette.background.paper,
            position: "relative",
          }}
          className="app-sidebar"
        >
          <List className="app-master-menu">
            {modules.map((module) => (
              <Fragment key={module.name}>
                {module.master ? (
                  <ListItemButton
                    id={`menu_${module.name}`}
                    className={`app-master-menu-item ${
                      _.includes(activeMenus, module.name)
                        ? "active-menu-item"
                        : ""
                    }`}
                    onClick={() => onClickMasterModule(module.name)}
                    selected={_.includes(activeMenus, module.name)}
                    style={{
                      color: isModuleSelected(module.name)
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    }}
                  >
                    <ListItemIcon>
                      <Icon
                        color={
                          isModuleSelected(module.name) ? "primary" : "action"
                        }
                        className="main-menu-icon"
                      >
                        {module.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: isModuleSelected(module.name)
                              ? 700
                              : 600,
                            fontSize: isModuleSelected(module.name) ? 15 : 14,
                          }}
                          gutterBottom={false}
                          className="main-menu-title"
                        >
                          {module.title}
                          <span>
                            {module.isBeta ? (
                              <Chip
                                className="beta-module-label"
                                color="primary"
                                size="small"
                                label="BETA"
                              />
                            ) : null}
                          </span>
                        </Typography>
                      }
                    />

                    {_.includes(activeMenus, module.name) ? (
                      <Icon style={{ lineHeight: 1 }}>expand_more</Icon>
                    ) : (
                      <Icon style={{ lineHeight: 1 }}>chevron_right</Icon>
                    )}
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    id={`menu_${module.name}`}
                    className={`app-master-menu-item ${
                      _.includes(activeMenus, module.name)
                        ? "active-menu-item"
                        : ""
                    }`}
                    component={Link}
                    to={module.path}
                    selected={currentPath.startsWith(`/${module.name}`)}
                    style={{
                      color: isModuleSelected(module.name)
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    }}
                  >
                    <ListItemIcon>
                      <Icon
                        color={
                          isModuleSelected(module.name) ? "primary" : "action"
                        }
                        className="main-menu-icon"
                      >
                        {module.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: isModuleSelected(module.name)
                              ? 700
                              : 600,
                            fontSize: isModuleSelected(module.name) ? 15 : 14,
                          }}
                          gutterBottom={false}
                          className="main-menu-title"
                        >
                          {module.title}
                          <span>
                            {module.isBeta ? (
                              <Chip
                                className="beta-module-label"
                                color="primary"
                                size="small"
                                label="BETA"
                              />
                            ) : null}
                          </span>
                        </Typography>
                      }
                    />
                  </ListItemButton>
                )}

                {module.subModules.length ? (
                  <Collapse
                    in={_.includes(activeMenus, module.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      className="app-sub-menu"
                      dense
                      sx={{ padding: 0 }}
                    >
                      {module.subModules.map((subModule) => {
                        const isSubModuleSelected = currentPath.startsWith(
                          pathMapping(module.name) + "/" + subModule.name
                        );
                        return (
                          <ListItemButton
                            className="sub-menu-item"
                            component={Link}
                            to={subModule.path}
                            // selected={isSubModuleSelected}
                            key={subModule.name}
                          >
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  <Grid item alignSelf={"center"}>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        ml: "40px",
                                        fontSize: 13,
                                      }}
                                      gutterBottom={false}
                                      className="sub-menu-title"
                                      color={
                                        isSubModuleSelected
                                          ? "primary"
                                          : "textPrimary"
                                      }
                                    >
                                      {subModule.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item alignSelf={"center"}>
                                    {subModule.isBeta ? (
                                      <Chip
                                        className="beta-module-label"
                                        color="primary"
                                        size="small"
                                        label="BETA"
                                      />
                                    ) : null}
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                ) : null}
              </Fragment>
            ))}
          </List>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              backgroundImage: "linear-gradient(to right, #0C1339, #000000)",
              height: 55,
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://www.venustusy.com/", "_self");
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                padding: 1,
              }}
            >
              <img
                src={vLogo}
                alt={"venustusy-logo"}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "25px",
                  width: "190px",
                }}
              />
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Fragment>
  );
};
