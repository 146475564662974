import React, { Fragment } from "react";
import { StyledPaper } from "../../components/styled-paper";
import { toast } from "../../../state/snackbar";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { selectedMember$, user$ } from "../../../state/user";
import { IBucket } from "../../module.interface";
import { StyledDonutChart } from "../../../charts-library/donut-chart";
import take from "lodash/take";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { SimplifiedTable } from "../../../components/simplified-table";
import { UUIDGenerator } from "../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IPieChart } from "./charts.types";

export const PieChart: React.FC<IPieChart> = ({
    fetchFunction,
    title,
    description,
    showDetails,
    detailsTitle,
    detialsSubTitle,
    colDefs,
}) => {
    const selectedMember = useBehaviourSubject(selectedMember$);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IBucket[]>([]);
    const [chartData, setChartData] = React.useState<IBucket[]>([]);
    const user = useBehaviourSubject(user$);
    const fetchData = () => {
        if (user) {
            setLoading(true);
            fetchFunction()
                .then((res) => {
                    setData(res.data);
                    setChartData(take(res.data, 5));
                })
                .catch(() => {
                    toast("Erorr happened while fetching top makes", "error");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useDeepCompareEffect(fetchData, [user, selectedMember]);
    return (
        <Fragment>
            <StyledPaper
                title={title}
                subtitle={description}
                expandDetails={true}
                onExpand={() => {
                    setIsDetailsOpen(true);
                }}
            >
                <StyledDonutChart
                    labels={chartData.map((i) => i.key)}
                    chartSeries={chartData.map((i) => i.doc_count)}
                    loading={loading}
                    title={title}
                />
            </StyledPaper>
            {isDetailsOpen && showDetails && colDefs && colDefs.length > 0 ? (
                <FullScreenModal
                    isOpen={isDetailsOpen}
                    onClose={() => {
                        setIsDetailsOpen(false);
                    }}
                    width="md"
                    title={detailsTitle}
                    subTitle={detialsSubTitle}
                    content={
                        <SimplifiedTable
                            loading={loading}
                            docs={data.map((i) => {
                                return {
                                    make_name: i.key,
                                    doc_count: i.doc_count,
                                    _id: UUIDGenerator(),
                                };
                            })}
                            colDefs={colDefs}
                        />
                    }
                />
            ) : null}
        </Fragment>
    );
};
