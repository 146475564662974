import React, { Fragment } from "react";
import { IMasterColumns } from "../modules/module.interface";
import { IESDoc } from "../app.interface";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RenderCell } from "../util/render.cell";
import { EmptyTableView } from "../views/Empty-table";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

interface ISimplifiedTable {
  loading: boolean;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
}

export const SimplifiedTable: React.FC<ISimplifiedTable> = ({
  loading,
  colDefs,
  docs,
}) => {
  return (
    <Fragment>
      <Box className="module-table-container">
        {loading ? <LinearProgress /> : null}
        <Box className="master-table-wrapper">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {colDefs.map((col) => (
                    <TableCell
                      key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                    >
                      <Typography
                        color="secondary"
                        variant="subtitle2"
                        className="table-cell-header"
                      >
                        {col.masterColumnTitle}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !isEmpty(docs) ? (
                    docs.map((row, pos) => (
                      <TableRow hover key={row._id}>
                        {colDefs.map((col) => (
                          <TableCell
                            key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                            className={`cell-${col.renderType}`}
                          >
                            <span className="data-cell">
                              {has(row, col.masterColumnName) &&
                              !isNull(get(row, col.masterColumnName)) &&
                              !isEmpty(
                                get(row, col.masterColumnName).toString()
                              ) &&
                              !isUndefined(get(row, col.masterColumnName)) &&
                              !isNaN(get(row, col.masterColumnName))
                                ? col.cellRender
                                  ? col.cellRender(row)
                                  : RenderCell(
                                      get(row, col.masterColumnName),
                                      col.renderType
                                    )
                                : "-"}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={colDefs.length}>
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
