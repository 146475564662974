import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
// import { syncUserRecord } from "../../../../util/sync.user";
import { isPermissionValid } from "../../../util/user-permission-validator";
import { ISection } from "../../module.interface";
import useDeepCompareEffect from "use-deep-compare-effect";
import { toast } from "../../../state/snackbar";
import { WritableCell } from "../components/writeable.cell";

const previewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "53vh",
  height: "25vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};

interface IEntityEditView {
  is_deleted?: boolean;
}

interface IEditEntity<T> {
  doc: T;
  setUpdateID: React.Dispatch<React.SetStateAction<string>>;
  onCloseModel: () => void;
  fieldDefinitions: ISection[];
  updateEntity: (entity: T) => Promise<void>;
  deleteEntity: (entity: T) => Promise<void>;
}

export const EditEntity = <T extends IEntityEditView>({
  doc,
  setUpdateID,
  onCloseModel,
  fieldDefinitions,
  updateEntity,
  deleteEntity,
}: IEditEntity<T>) => {
  const [entity, setEntity] = React.useState<T>({ ...doc });
  const [oldEntity, setOldEntity] = React.useState<T>({ ...doc });
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);
  const [deleteCounter, setDeleteCounter] = React.useState<boolean>(false);

  const handleChange = (
    e: string | number | string[] | number[],
    field: string
  ) => {
    setEntity({ ...entity, [field]: e });
  };
  const handleChangeObj = (newEntity: T) => {
    setEntity({
      ...entity,
      ...newEntity,
    });
  };

  const handleSubmit = () => {
    setSubmitCounter(true);
    const updatedEntity: T = { ...entity };
    updateEntity(updatedEntity)
      .then(() => {
        toast("Entity updated successfully", "success");
        setOldEntity({ ...entity });
      })
      .catch(() => {
        toast("Error occurred while updating the entity", "error");
      })
      .finally(() => {
        setSubmitCounter(false);
      });
  };

  const deleteEntityHandler = () => {
    setDeleteCounter(true);
    deleteEntity(doc)
      .then(() => {
        toast("Entity deleted successfully", "success");
        setIsDeleteModalOpen(false);
        onCloseModel();
      })
      .catch(() => {
        toast("Error occurred while deleting the entity", "error");
      })
      .finally(() => {
        setDeleteCounter(false);
      });
  };

  useDeepCompareEffect(() => {
    setHasChanged(!_.isEqual(entity, oldEntity));
  }, [entity, oldEntity]);

  return (
    <Fragment>
      <Grid container>
        {fieldDefinitions.map((group, i) => (
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            key={`${group.section}-${i}`}
          >
            {group.section && (
              <Grid item xs={12}>
                <Typography variant="h6">{group.section}</Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
            )}
            {group.fields.map((fieldProps, i) => (
              <WritableCell
                key={i}
                entity={entity}
                handleChange={handleChange}
                handleChangeObj={handleChangeObj}
                {...fieldProps}
              />
            ))}
          </Grid>
        ))}

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <LoadingButton
                loading={deleteCounter}
                size="small"
                color="error"
                variant="outlined"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete Entity
              </LoadingButton>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={() => setEntity({ ...oldEntity })}
                    disabled={!hasChanged || submitCounter}
                  >
                    Revert
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!hasChanged}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isDeleteModalOpen ? (
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Fragment>
            <Box sx={previewStyle}>
              <Grid
                container
                height={"100%"}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center" sx={{ mt: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h5"
                            color="secondary"
                            margin={2}
                            sx={{ fontSize: "2.8vh" }}
                          >
                            Are you sure?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            sx={{ fontSize: "2vh", display: "inline", mt: 1 }}
                          >
                            This action cannot be undone.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    width: "100%",
                    paddingX: 3,
                    bottom: 15,
                  }}
                >
                  <Grid
                    container
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <LoadingButton
                        loading={deleteCounter}
                        disabled={
                          submitCounter ||
                          !isPermissionValid("inventory.delete")
                        }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={deleteEntityHandler}
                      >
                        Delete
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        disabled={deleteCounter}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        </Modal>
      ) : null}
    </Fragment>
  );
};
