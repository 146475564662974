import { Skeleton, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";

interface IDonutChart {
  loading: boolean;
  labels: string[];
  chartSeries: number[];
  title: string;
  containerHeight?: number;
}

export const StyledDonutChart: React.FC<IDonutChart> = ({
  loading,
  labels,
  chartSeries,
  title,
  containerHeight = 300,
}) => {
  const theme = useTheme();
  const chartOptions: ApexCharts.ApexOptions = {
    labels: labels,
    dataLabels: {
      enabled: false,
      style: {
        colors: [theme.palette.text.primary],
      },
      dropShadow: {
        enabled: false,
      },
    },
    noData: {
      text: loading ? "Loading..." : "Data not available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    },
    theme: {
      palette: "palette1",
    },
    stroke: {
      show: false,
      curve: "smooth",
      lineCap: "butt",
      width: 0.7,
      dashArray: 0,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {
      height: "auto",
      foreColor: theme.palette.text.primary,
      type: "donut",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },

    responsive: [
      {
        breakpoint: 30,
        options: {
          chart: {
            width: 200,
            height: 300,
          },
        },
      },
    ],
    yaxis: {
      max: 300,
    },
    legend: {
      show: true,
      fontSize: "10rem",
      fontFamily: "Montserrat",
      position: "bottom",
      itemMargin: {
        horizontal: 3,
        vertical: 7,
      },
      horizontalAlign: "center",
      labels: {
        colors: theme.palette.text.secondary,
        useSeriesColors: false,
      },
    },
    plotOptions: {
      area: {
        fillTo: "origin",
      },
      pie: {
        expandOnClick: false,
        customScale: 1,
        donut: {
          size: "80%",
          background: "transparent",
          labels: {
            show: true,

            name: {
              show: true,
              formatter: () => title,
              color: theme.palette.primary.main,
            },
            value: {
              show: true,
              fontFamily: "Montserrat",
              color: theme.palette.text.primary,
            },
            total: {
              show: true,
              showAlways: true,
              fontFamily: "Montserrat",
              label: title,
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <ResponsiveContainer height={containerHeight}>
        {loading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="donut"
          />
        )}
      </ResponsiveContainer>
    </Fragment>
  );
};
