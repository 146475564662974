import React, { Fragment } from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import { IESDoc } from "../../../app.interface";
import { ISection } from "../../module.interface";
import { AppAssetsSectionBox } from "../../../views/app.section.view";
import { AppDocView } from "../../../views/app.doc.view";

const IDetail: React.FC<{
  doc: IESDoc<any>;
  fieldDefinitions: ISection[];
}> = ({ doc, fieldDefinitions }) => {
  return (
    <Fragment>
      <Box>
        {fieldDefinitions.map(({ section, fields }, index) => (
          <AppAssetsSectionBox
            key={index}
            title={_.startCase(section)}
            type={doc._id}
          >
            <AppDocView doc={doc} colDefs={fields} />
          </AppAssetsSectionBox>
        ))}
      </Box>
    </Fragment>
  );
};

export default IDetail;
