import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../../app.interface";
import { toast } from "../../../../state/snackbar";
import { UUIDGenerator } from "../../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ValidateEmail } from "../../../../util/validator";
import { SetRolePermissions } from "./set-role-permissions.view";
import {
  DeleteStaffRecord,
  FetchUserRoles,
  GetUserPermissions,
  UpdateCurrentUser,
} from "../../../../services/settings.service";
// import { syncUserRecord } from "../../../../util/sync.user";
import { isPermissionValid } from "../../../../util/user-permission-validator";

const previewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "53vh",
  height: "25vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};
const EditUserRecord: React.FC<{
  doc: IESDoc;
  setUpdateID: React.Dispatch<React.SetStateAction<string>>;
  onCloseModel: () => void;
  permissionsStructure: string[];
}> = ({ doc, setUpdateID, onCloseModel, permissionsStructure }) => {
  const [employee, setEmployee] = React.useState<{
    employee_name: string;
    employee_email: string;
    role_name: string;
    is_disabled: boolean;
    is_deleted: boolean;
    permissions_list: string[];
  }>({
    employee_name: doc.staff_name,
    employee_email: doc.staff_email,
    role_name: doc.role_name,
    is_disabled: doc.is_disabled,
    is_deleted: doc.is_deleted,
    permissions_list: doc.permissions_list,
  });
  const [oldEmployee, setOldEmployee] = React.useState<{
    employee_name: string;
    employee_email: string;
    role_name: string;
    is_disabled: boolean;
    is_deleted: boolean;
    permissions_list: string[];
  }>({
    employee_name: doc.staff_name,
    employee_email: doc.staff_email,
    role_name: doc.role_name,
    is_disabled: doc.is_disabled,
    is_deleted: doc.is_deleted,
    permissions_list: doc.permissions_list,
  });

  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [fetchingUserRoles, setFetchingUserRoles] =
    React.useState<boolean>(false);

  const [userRolesList, setUserRolesList] = React.useState<string[]>([]);
  const [resultedPermissions, setResultedPermissions] = React.useState<
    string[]
  >(doc.permissions_list);
  const [rolePermissions, setRolePermissions] = React.useState<string[]>(
    doc.permissions_list
  );
  const [customizingPermissionsModal, setCustomizingPermissionsModal] =
    React.useState<boolean>(false);
  const [deleteCounter, setDeleteCounter] = React.useState<boolean>(false);

  const getUserRoles = (role: string) => {
    setFetchingUserRoles(true);
    GetUserPermissions(role)
      .then((response) => {
        const x: IESDoc[] = response.data;
        setResultedPermissions(x[0].default_permission);
        setRolePermissions(x[0].default_permission);
      })
      .catch(() => {
        toast("Error happened while getting user permissions", "error");
      })
      .finally(() => {
        setFetchingUserRoles(false);
      });
  };
  const handleChange = (e: any, field: string) => {
    const x = { ...employee };
    switch (field) {
      case "name":
        x.employee_name = e.target.value;
        break;

      case "role":
        x.role_name = e.target.value;
        getUserRoles(_.snakeCase(e.target.value));
        break;
      case "is_disabled":
        x.is_disabled = e.target.checked;
        break;

      case "email":
        x.employee_email = e.target.value;
        break;
    }
    setEmployee(x);
  };

  const handleSubmit = () => {
    setSubmitCounter(true);
    const updatedUserRecord: IESDoc = {
      _id: doc._id,
      staff_name: employee.employee_name,

      staff_email: employee.employee_email,
      role_name: employee.role_name,
      is_disabled: employee.is_disabled,
      permissions_list: resultedPermissions,
    };
    UpdateCurrentUser(updatedUserRecord)
      .then(() => {
        toast("user updated successfully", "success");
        setOldEmployee({ ...employee });
        setUpdateID(UUIDGenerator());

        // syncUserRecord(employee);
      })
      .catch(() => {
        toast("error happened while updating the user", "error");
      })
      .finally(() => {
        setSubmitCounter(false);
      });
  };
  const deleteUser = () => {
    setDeleteCounter(true);
    DeleteStaffRecord([doc])
      .then(() => {
        toast("user deleted successfully", "success");
        setIsDeleteModalOpen(false);
        setUpdateID(UUIDGenerator());
        onCloseModel();
      })
      .catch(() => {
        toast("error happened while deleting the user", "error");
      })
      .finally(() => {
        setDeleteCounter(false);
      });
  };

  const fetchUserRoles = () => {
    setFetchingUserRoles(true);
    FetchUserRoles()
      .then((response) => {
        const rolesData: IESDoc[] = response.data;
        const list: string[] = [];
        rolesData.forEach((item) => {
          list.push(item.role);
        });
        setUserRolesList(list);
      })
      .catch(() => {
        toast("Error fetching user roles", "error");
      })
      .finally(() => {
        setFetchingUserRoles(false);
      });
  };

  useEffect(fetchUserRoles, []);

  //   useEffect(fetchIncidentsList, []);
  useDeepCompareEffect(() => {
    if (
      !_.isEqual(employee, oldEmployee) ||
      !_.isEqual(_.sortBy(resultedPermissions), _.sortBy(doc.permissions_list))
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [employee, oldEmployee, resultedPermissions, doc]);

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3, mb: 1 }}>
          <Typography
            color="secondary"
            variant="h6"
            sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
          >
            User Information
          </Typography>
          <Divider />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Employee Name"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "name");
                }}
                value={employee.employee_name}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Email"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "email");
                }}
                value={employee.employee_email}
                error={
                  !_.isEmpty(employee.employee_email)
                    ? !ValidateEmail(employee.employee_email)
                    : undefined
                }
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="User_Role">User Role</InputLabel>
                <Select
                  labelId="User_role"
                  id="User_Role"
                  label="User_role"
                  fullWidth
                  onChange={(e) => handleChange(e, "role")}
                  value={employee.role_name}
                >
                  <MenuItem value="Super Admin">Super Admin</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                  <MenuItem value="Trainee">Trainee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item alignSelf="center">
              <Tooltip title="Customize The Permissions">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustomizingPermissionsModal(true);
                  }}
                >
                  <Icon color="info">settings</Icon>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={employee.is_disabled}
                            onChange={(e) => {
                              handleChange(e, "is_disabled");
                            }}
                            name="isDisabled"
                          />
                        }
                        label="Disable User"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <LoadingButton
                loading={deleteCounter}
                size="small"
                color="error"
                startIcon={<Icon>delete</Icon>}
                variant="outlined"
                disabled={
                  submitCounter
                  // || !isCiruActionAllowed("user", "delete")
                }
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete User
              </LoadingButton>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    startIcon={<Icon>history</Icon>}
                    onClick={() => {
                      setEmployee({ ...oldEmployee });
                      setResultedPermissions(doc.permissions_list);
                    }}
                    disabled={!hasChanged || submitCounter}
                  >
                    Revert
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                      !hasChanged
                      //  || !isCiruActionAllowed("user", "edit")
                    }
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteModalOpen ? (
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Fragment>
            <Box sx={previewStyle}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center" sx={{ mt: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h5"
                            color="secondary"
                            margin={2}
                            sx={{ fontSize: "2.8vh" }}
                          >
                            Are you sure?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            color="primary"
                            marginLeft={2}
                            sx={{ fontSize: "2vh", display: "inline" }}
                          >
                            {employee.employee_name}&nbsp;
                          </Typography>
                          <Typography
                            sx={{ fontSize: "2vh", display: "inline", mt: 1 }}
                          >
                            will be deleted immediatly.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ position: "absolute", right: 15, bottom: 15 }}
                >
                  <Grid container>
                    <Grid item>
                      <LoadingButton
                        loading={deleteCounter}
                        disabled={
                          submitCounter || !isPermissionValid("users.delete")
                        }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </LoadingButton>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        disabled={deleteCounter}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        </Modal>
      ) : null}

      {customizingPermissionsModal ? (
        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={true}
          onClose={() => {
            setCustomizingPermissionsModal(false);
            setResultedPermissions(rolePermissions);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Customize User Permissions
          </DialogTitle>
          <DialogContent>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Stack spacing={2}>
              <Typography sx={{ fontSize: 16 }}>
                Select the Permissions to be Customized to the selected User.
              </Typography>
              {fetchingUserRoles ? (
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Skeleton variant="rectangular" height={300} />
                  </Grid>
                </Grid>
              ) : (
                <Box>
                  <SetRolePermissions
                    roleName={""}
                    permissionsStructureList={permissionsStructure}
                    resultedPermissions={resultedPermissions}
                    setResultedPermissions={setResultedPermissions}
                    isUpdating={true}
                  />
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={"flex-end"} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setCustomizingPermissionsModal(false);
                    setResultedPermissions(rolePermissions);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={
                    // !isCiruActionAllowed("user", "edit") ||
                    _.isEqual(
                      _.sortBy(resultedPermissions),
                      _.sortBy(doc.permissions_list)
                    )
                  }
                  onClick={() => {
                    setCustomizingPermissionsModal(false);
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  );
};

export default EditUserRecord;
