import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/webConstruction.json";

interface IUnderConstructionPage {
  height?: string;
  width?: string;
}
export const UnderConstructionPage: React.FC<IUnderConstructionPage> = ({
  height = "60vh",
  width = "80vw",
}) => {
  return (
    <Box sx={{ width: width, height: height }}>
      <Grid container justifyContent="center" sx={{ height: "60vh" }}>
        <Grid item alignSelf="center">
          <Stack>
            <Lottie
              options={{
                animationData: animationData,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={500}
              height={500}
            />
            <Box sx={{ mt: -6 }}>
              <Typography
                sx={{ textAlign: "center", fontSize: 45, fontWeight: 700 }}
                color={"primary"}
              >
                Coming Soon
              </Typography>
              <Typography
                sx={{ textAlign: "center", fontSize: 18, fontWeight: 500 }}
                color="text.secondary"
              >
                This page is still under Construction
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
