import {
  Box,
  Typography,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { Fragment } from "react";
import { UserPreferences } from "../user.preferences";

interface IPreBuildThemes {
  theme: string;
}

export const PreBuildThemes: React.FC<IPreBuildThemes> = ({ theme }) => {
  const [themeOptions] = React.useState<{ label: string; value: string }[]>([
    { label: "Midnight", value: "midnight-ember" },
    { label: "Orange", value: "orange" },
    { label: "Winter", value: "winter-cold" },
    { label: "Aurora", value: "aurora" },
    { label: "Desert", value: "desert-mirage" },
  ]);
  const [selectedTheme, setSelectedTheme] = React.useState<string>(theme);

  return (
    <Fragment>
      <Box className="app-settings-item hv-themes-wrapper">
        <Typography variant="h6" color="primary" className="title">
          Themes
        </Typography>
        <Box className="contents">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={theme ? theme : "default-theme"}
            onChange={(ev, val) => {
              setSelectedTheme(val);
              UserPreferences.setLocal("selected_theme", val);
            }}
          >
            {themeOptions.map((t) => (
              <Grid container spacing={2} alignItems="center" key={t.label}>
                <Grid item>
                  <FormControlLabel
                    key={t.value}
                    value={t.value}
                    checked={selectedTheme === t.value}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className="theme-title"
                      >
                        {t.label}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <Box className={`theme-preview-box theme-${t.value}`}>
                    <Box className="color-swatch-wrapper">
                      <Box className="primary-color color-swatch"></Box>
                      <Box className="secondary-color color-swatch"></Box>
                      <Box className="bg-color color-swatch"></Box>
                      <Box className="paper-color color-swatch"></Box>
                      <Box className="text-primary-color color-swatch"></Box>
                      <Box className="text-secondary-color color-swatch"></Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </RadioGroup>
        </Box>
      </Box>
    </Fragment>
  );
};
