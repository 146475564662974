import { BehaviorSubject } from "rxjs";

export class LocalStorageBehaviorSubject<T> extends BehaviorSubject<T> {
  private propName: string;

  constructor(propName: string, defaultValue?: T) {
    const localStorageValue = JSON.parse(
      localStorage.getItem(propName) || "null"
    );
    const sessionStorageValue = JSON.parse(
      sessionStorage.getItem(propName) || "null"
    );
    super(
      localStorageValue !== null
        ? localStorageValue
        : sessionStorageValue !== null
        ? sessionStorageValue
        : defaultValue
    );
    this.propName = propName;
  }

  public next(value: T) {
    localStorage.setItem(this.propName, JSON.stringify(value));
    super.next(value);
  }
}
