import { ReactNode } from "react";
import _isNull from "lodash/isNull";
import _isUndefined from "lodash/isUndefined";
import _isNaN from "lodash/isNaN";
import _isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import _isEqual from "lodash/isEqual";
import _uniqWith from "lodash/uniqWith";
import moment from "moment";
import { Tooltip, Typography, Icon, Grid, Chip, Box } from "@mui/material";
import { UrlRenderer } from "./url-render";
import tempImg from "../../modules/inventory/assets/test-car-image.png";
import isEmpty from "lodash/isEmpty";
import { UUIDGenerator } from "../uuid.generator";

export const RenderDateTime = (value: string | number): ReactNode => {
  if (_isNull(value)) {
    return "";
  }
  if (_isUndefined(value)) {
    return "";
  }
  if (_isNaN(value)) {
    return "";
  }
  if (_isEmpty(value.toString())) {
    return "";
  }
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
      {moment(parsedValue).isValid()
        ? moment(parsedValue).format("LLL")
        : parsedValue}
    </span>
  );
};
export const RenderText = (value: string, toolTipped?: boolean): ReactNode => {
  let parsedValue = value;
  try {
    parsedValue = decodeURIComponent(value);
  } catch (error) {
    parsedValue = value;
  }
  if (toolTipped === true) {
    return (
      <Tooltip title={parsedValue} placement="top-start">
        <Typography>
          <span>{parsedValue}</span>
        </Typography>
      </Tooltip>
    ) as ReactNode;
  } else {
    return (<span>{parsedValue}</span>) as ReactNode;
  }
};
export const RenderImage = (value: string): ReactNode => {
  if (value === "unavailable") {
    return (
      <Box>
        <img src={tempImg} alt="temp-img" height={70} width={"auto"} />
      </Box>
    ) as ReactNode;
  } else {
    return (
      <Box>
        <img
          src={value}
          alt="value"
          style={{
            width: "auto",
            height: 70,
            maxWidth: "-webkit-fill-available",
          }}
        />
      </Box>
    ) as ReactNode;
  }
};
export const RenderPartsView = (value: any): ReactNode => {
  return (
    <span>
      {isEmpty(value) ? (
        "-"
      ) : (
        <Grid container spacing={0.5}>
          {value.map((i: any) => (
            <Grid item key={UUIDGenerator()}>
              <Chip
                variant="filled"
                size="small"
                color={i.status === "available" ? "success" : "error"}
                label={
                  <Typography
                    sx={{ color: "white", fontWeight: 500, fontSize: "11px" }}
                  >
                    {i.part_name}
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </span>
  ) as ReactNode;
};

export const RenderDate = (value: string | number): ReactNode => {
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <strong>
        {moment(parsedValue).isValid()
          ? moment(parsedValue).format("MMMM Do YYYY")
          : parsedValue}
      </strong>
    </span>
  ) as ReactNode;
};
export const RenderTime = (value: string | number): ReactNode => {
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <strong>
        {moment(parsedValue).isValid()
          ? moment(parsedValue).format("MMMM Do YYYY, h:mm:ss A")
          : parsedValue}
      </strong>
    </span>
  ) as ReactNode;
};

export const RenderNumber = (value: number): ReactNode => {
  return (<span>{value.toLocaleString("en")}</span>) as ReactNode;
};

export const RenderLong = (value: string): ReactNode => {
  return (<span>{value}</span>) as ReactNode;
};

export const RenderUrl = (value: string, multiline?: boolean): ReactNode => {
  let parsedValue = value;

  try {
    parsedValue = decodeURIComponent(value);
  } catch (error) {
    parsedValue = value;
  }
  return <UrlRenderer url={parsedValue} multiline={multiline ? true : false} />;
};

export const RenderTitle = (name: string): ReactNode => {
  return _startCase(name);
};
export const RenderBoolean = (
  value: null | undefined | string | number | boolean | string[] | number[]
): ReactNode => {
  if (_isNull(value)) {
    return "";
  }
  if (_isUndefined(value)) {
    return "";
  }
  if (_isNaN(value)) {
    return "";
  }
  if (_isEmpty(value.toString())) {
    return "";
  }
  switch (typeof value) {
    case "boolean":
      return value ? (
        <Icon color="primary">done</Icon>
      ) : (
        <Icon color="primary">clear</Icon>
      );
    case "string":
      return value && value === "true" ? (
        <Icon color="primary">done</Icon>
      ) : (
        <Icon color="primary">clear</Icon>
      );
    case "number":
      return value ? (
        <Icon color="primary">done</Icon>
      ) : (
        <Icon color="primary">clear</Icon>
      );
    default:
      return value ? (
        <Icon color="primary">done</Icon>
      ) : (
        <Icon color="primary">clear</Icon>
      );
  }
};

export const RenderArray = (
  params: any[],
  type: "url" | "title" | "tags" | "title_tags" | "severity" | "none",
  limit?: number,
  inline?: boolean
): ReactNode => {
  if (_isNull(params)) {
    return "-";
  }
  if (_isUndefined(params)) {
    return "-";
  }
  if (_isNaN(params)) {
    return "-";
  }
  if (_isEmpty(params)) {
    return "-";
  }
  const uniqueItems = _uniqWith(params, _isEqual);
  const stackLength = uniqueItems.length;
  const renderingItems = limit
    ? stackLength === limit
      ? uniqueItems
      : uniqueItems.filter((f, index) => index < limit)
    : uniqueItems;

  const remainingItems = limit
    ? stackLength === limit
      ? 0
      : Math.abs(uniqueItems.length - limit)
    : 0;
  if (_isEmpty(renderingItems)) {
    return "-";
  }
  switch (type) {
    case "tags":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              <Chip size="small" variant="outlined" label={t}></Chip>
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "title":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              {_startCase(t)}
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "url":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={12}>
              <UrlRenderer url={t} multiline={false} />
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "title_tags":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              <Chip
                size="small"
                variant="outlined"
                label={_startCase(t)}
              ></Chip>
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );

    default:
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              {t}
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
  }
};
