import { Alert, Box, LinearProgress, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lottie/loading-car.json";
import venLogo from "../assets/logo/venestusy-logo.png";
import { useBehaviourSubject } from "../hooks/user-obsevrable";
import { user$ } from "../state/user";
import { FetchMemberData } from "../services/user.service";
import { has, isArray, isEmpty, isNumber, uniq } from "lodash";

interface IUserInitalizer {
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  loadingCount: number;
  total_tasks: number;
}

export const UserInitalizer: React.FC<IUserInitalizer> = ({
  setLoadingCount,
  loadingCount,
  total_tasks,
}) => {
  const user = useBehaviourSubject(user$);
  const [helperText, setHelperText] = React.useState<string>(
    "Initalizing your user ..."
  );
  const [error, setError] = React.useState<string>("");

  const fetchUserData = () => {
    setHelperText("Getting your account info ...");
    if (user) {
      setLoadingCount(1);
      setHelperText("Getting your organization info ...");
      if (has(user, "member_id") && isNumber(user.member_id)) {
        FetchMemberData(user.member_id)
          .then((memberDataRes) => {
            setHelperText("Saving your organization info ...");
            let membersIds: string[] = [user.member_id];
            const doc = memberDataRes.data[0];
            if (
              user.is_admin_account === true &&
              has(doc, "childs_ids") &&
              !isEmpty(doc.childs_ids) &&
              isArray(doc.childs_ids)
            ) {
              membersIds = [user.member_id, ...doc.childs_ids];
            }
            const val = JSON.stringify(uniq(membersIds));

            sessionStorage.setItem("allowed_members", val);
            localStorage.setItem("allowed_members", val);

            const currentMember = JSON.stringify({
              member_id: user.member_id,
              member_name: user.member_name,
              is_admin_account: user.is_admin_account,
            });
            sessionStorage.setItem("selected_member", currentMember);
            localStorage.setItem("selected_member", currentMember);
          })
          .catch(() => {
            setError(
              "Error Code:MME-01, Contact our support on support@venustusy.com"
            );
          })
          .finally(() => {
            setLoadingCount(0);
            setHelperText("You are all set, Enjoy !");
          });
      } else {
        setError(
          "User info needs to be synchronized, Contact our support on support@venustusy.com"
        );
      }
    }
  };

  useEffect(fetchUserData, [user]);

  //   add tips later
  return (
    <Fragment>
      <Box sx={{ height: "100vh", position: "relative" }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ mt: -20 }}>
            <Lottie
              options={{
                animationData: animationData,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={500}
              height={500}
            />
            <Box>
              {isEmpty(error) ? (
                <Stack
                  spacing={1.5}
                  sx={{ mt: -15, alignItems: "center", display: "flex" }}
                >
                  <Box sx={{ width: "350px" }}>
                    <LinearProgress
                      variant="determinate"
                      value={(1 - loadingCount / total_tasks) * 100}
                      sx={{
                        borderRadius: "5px !important",
                        height: "6px !important",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ textAlign: "center", fontSize: 16, fontWeight: 500 }}
                    color={"text.secondary"}
                  >
                    {helperText}
                  </Typography>
                </Stack>
              ) : (
                <Box sx={{ mt: -15 }}>
                  <Alert
                    severity="error"
                    sx={{ fontWeight: 500 }}
                    variant="filled"
                  >
                    {error}
                  </Alert>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
        <Box sx={{ position: "absolute", right: 5, bottom: 5 }}>
          <Stack direction={"row"}>
            <img
              alt="Venustusy Logo"
              src={venLogo}
              height={40}
              width={40}
              style={{ alignSelf: "center" }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                alignSelf: "center",
                ml: -1.2,
              }}
            >
              <em>Ven</em>
              <span style={{ fontWeight: 700, color: "#0C1339" }}>Auth</span>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
};
