import {
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";

interface IStyledPaper {
  children: ReactNode;
  title: string;
  subtitle?: string;
  expandDetails?: boolean;
  onExpand?: () => void;
}
export const StyledPaper: React.FC<IStyledPaper> = ({
  children,
  title,
  subtitle,
  expandDetails,
  onExpand,
}) => {
  return (
    <Fragment>
      <Paper sx={{ padding: 1 }} elevation={3}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography sx={{ fontSize: 13, fontWeight: 600 }} color="primary">
              {title}
            </Typography>
            {subtitle ? (
              <Typography
                sx={{ fontSize: 11, fontWeight: 500 }}
                color="text.secondary"
              >
                {subtitle}
              </Typography>
            ) : null}
          </Grid>
          {expandDetails && onExpand ? (
            <Grid item>
              <IconButton
                onClick={() => {
                  onExpand();
                }}
                color="primary"
              >
                <Icon>settings_overscan</Icon>
              </IconButton>
            </Grid>
          ) : null}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
