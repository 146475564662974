import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";

import { LogOffModel } from "../logout.model";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import { user$ } from "../../state/user";
import { ResetPassModel } from "../member/reset-password";
interface IAccessibility {
  onClose: () => void;
}

export const Accessibility: React.FC<IAccessibility> = ({ onClose }) => {
  const user = useBehaviourSubject(user$);
  const [logoutModel, setLogoutModel] = React.useState<boolean>(false);
  const [resetPasswordModel, setResetPasswordModel] =
    React.useState<boolean>(false);

  const onSignOut = () => {
    setLogoutModel(true);
  };
  const onResetPassword = () => {
    setResetPasswordModel(true);
  };
  const onCloseModel = () => {
    onClose();
    setLogoutModel(false);
    setResetPasswordModel(false);
  };
  return (
    <Fragment>
      <Box className="app-settings-item app-themes-wrapper">
        <Typography variant="h6" color="primary" className="title">
          Accessibility
        </Typography>
        <Box className="contents">
          <List aria-label="main mailbox folders" dense>
            <ListItem button disableGutters onClick={onResetPassword}>
              <ListItemIcon>
                <Icon>sync_lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Reset Password" />
            </ListItem>
            <ListItem button disableGutters onClick={onSignOut}>
              <ListItemIcon>
                <Icon>power_settings_new</Icon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItem>
          </List>
        </Box>
      </Box>
      {logoutModel && user ? (
        <LogOffModel user={user} onClose={onCloseModel} />
      ) : null}
      {resetPasswordModel && user ? (
        <ResetPassModel
          onClose={onCloseModel}
          isOpen={resetPasswordModel}
        />
      ) : null}
    </Fragment>
  );
};
