import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import _, { set } from "lodash";
import React, { Fragment } from "react";
import { IESDoc } from "../../../../app.interface";
import { toast } from "../../../../state/snackbar";
import { UUIDGenerator } from "../../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
// import { syncUserRecord } from "../../../../util/sync.user";
import { isPermissionValid } from "../../../../util/user-permission-validator";
import {
  IMemberCreateRequest,
  IMemberRequest,
  IMemberRequestValue,
  IMemberRequestValueKeys,
} from "../../../module.interface";
import {
  InternalDeleteRequestRecord,
  InternalUpdateRequestRecord,
} from "../../../../services/admin-panel/internal.request.service";

interface IRequestRecordEditView extends IMemberRequest {
  is_deleted: boolean;
}

const previewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "53vh",
  height: "25vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};
const EditRequestRecord: React.FC<{
  doc: IESDoc;
  setUpdateID: React.Dispatch<React.SetStateAction<string>>;
  onCloseModel: () => void;
  permissionsStructure: string[];
}> = ({ doc, setUpdateID, onCloseModel, permissionsStructure }) => {
  const [request, setRequest] = React.useState<IRequestRecordEditView>({
    created_by: doc.created_by,
    action: doc.action,
    module: doc.module,
    value: doc.value,
    field: doc.field,
    status: doc.status,
    old_value: doc.old_value,
    last_update: doc.last_update,
    is_deleted: doc.is_deleted,
  });
  const [oldRequest, setOldRequest] = React.useState<IRequestRecordEditView>({
    created_by: doc.created_by,
    action: doc.action,
    module: doc.module,
    value: doc.value,
    field: doc.field,
    status: doc.status,
    old_value: doc.old_value,
    last_update: doc.last_update,
    is_deleted: doc.is_deleted,
  });

  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [deleteCounter, setDeleteCounter] = React.useState<boolean>(false);

  const handleChange = (e: any, field: string) => {
    const x = { ...request };
    switch (field) {
      case "action":
        x.action = e.target.value;
        break;
      case "module":
        x.module = e.target.value;
        break;
      case "create_by":
        x.created_by = e.target.value;
        break;
      case "field":
        x.field = e.target.value;
        break;
      case "status":
        x.status = e.target.value;
        break;
    }
    setRequest(x);
  };

  const [requestValue, setRequestValue] = React.useState<IMemberRequestValue>({
    ...request.value,
  });
  const [requestValueOld, setRequestValueOld] =
    React.useState<IMemberRequestValue>({
      ...oldRequest.value,
    });
  const handelValueChange = (key: IMemberRequestValueKeys, value: string) => {
    const x = { ...request };
    let valueObj = x.value;
    valueObj[key] = value;
    x.value = valueObj;
    setRequestValue(valueObj);
    setRequest(x);
  };

  const handleSubmit = () => {
    setSubmitCounter(true);
    const updatedUserRecord: IESDoc = {
      _id: doc._id,
      ...request,
    };
    InternalUpdateRequestRecord(updatedUserRecord)
      .then(() => {
        toast("user updated successfully", "success");
        setOldRequest({ ...request });
        setUpdateID(UUIDGenerator());
        // syncUserRecord(request);
      })
      .catch(() => {
        toast("error happened while updating the user", "error");
      })
      .finally(() => {
        setSubmitCounter(false);
      });
  };

  const deleteUser = () => {
    setDeleteCounter(true);
    InternalDeleteRequestRecord([doc])
      .then(() => {
        toast("user deleted successfully", "success");
        setIsDeleteModalOpen(false);
        setUpdateID(UUIDGenerator());
        onCloseModel();
      })
      .catch(() => {
        toast("error happened while deleting the user", "error");
      })
      .finally(() => {
        setDeleteCounter(false);
      });
  };

  useDeepCompareEffect(() => {
    if (
      !_.isEqual(request, oldRequest) ||
      !_.isEqual(requestValue, requestValueOld)
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [request, requestValue, oldRequest, doc]);

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3, mb: 1 }}>
          <Typography
            color="secondary"
            variant="h6"
            sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
          >
            Request Information
          </Typography>
          <Divider />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Created By"
                size="small"
                type="text"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "created_by");
                }}
                value={request.created_by}
              />
            </Grid>

            {/* //TODO: Select */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Action"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "action");
                }}
                value={request.action}
              />
            </Grid>

            {/* //TODO: Select */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Module"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "module");
                }}
                value={request.module}
              />
            </Grid>

            {/* //TODO: Select */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Field"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "field");
                }}
                value={request.field}
              />
            </Grid>

            {/* //TODO: Select */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Status"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "status");
                }}
                value={request.status}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3, mb: 1 }}>
          <Typography
            color="secondary"
            variant="h6"
            sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
          >
            Value
          </Typography>
          <Divider />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {Object.keys(request.value).map((key) => {
              return (
                <Grid key={key} item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    label={key}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      handelValueChange(
                        key as IMemberRequestValueKeys,
                        e.target.value
                      );
                    }}
                    value={requestValue[key as IMemberRequestValueKeys]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <LoadingButton
                loading={deleteCounter}
                size="small"
                color="error"
                startIcon={<Icon>delete</Icon>}
                variant="outlined"
                disabled={
                  submitCounter
                  // || !isCiruActionAllowed("user", "delete")
                }
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete User
              </LoadingButton>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    startIcon={<Icon>history</Icon>}
                    onClick={() => {
                      setRequest({ ...oldRequest });
                      setRequestValue({ ...requestValueOld });
                    }}
                    disabled={!hasChanged || submitCounter}
                  >
                    Revert
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                      !hasChanged
                      //  || !isCiruActionAllowed("user", "edit")
                    }
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteModalOpen ? (
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Fragment>
            <Box sx={previewStyle}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center" sx={{ mt: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h5"
                            color="secondary"
                            margin={2}
                            sx={{ fontSize: "2.8vh" }}
                          >
                            Are you sure?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            color="primary"
                            marginLeft={2}
                            sx={{ fontSize: "2vh", display: "inline" }}
                          >
                            {request.action} {request.module}&nbsp;
                          </Typography>
                          <Typography
                            sx={{ fontSize: "2vh", display: "inline", mt: 1 }}
                          >
                            will be deleted immediately.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ position: "absolute", right: 15, bottom: 15 }}
                >
                  <Grid container>
                    <Grid item>
                      <LoadingButton
                        loading={deleteCounter}
                        disabled={
                          submitCounter ||
                          !isPermissionValid("inventory.delete")
                        }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </LoadingButton>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        disabled={deleteCounter}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default EditRequestRecord;
