import { ThemeOptions } from "@mui/material";

export const DefaultTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#e8e8e8",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#7f8c8d",
    },
    primary: {
      main: "#4b77be",
    },
    secondary: {
      main: "#c0392b",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
          height: 24,
        },
        deleteIcon: {
          fontSize: 17,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          ".MuiTypography-button": {
            whiteSpace: "nowrap",
            marginBottom: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          lineHeight: ".9em",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
};

export const OrangeTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#D6E4E5",
      paper: "#EFF5F5",
    },
    text: {
      primary: "#222222",
      secondary: "#707070",
    },
    primary: {
      main: "#EB6440",
    },
    secondary: {
      main: "#497174",
    },
  },
  typography: {
    fontFamily: ["Raleway"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};

export const WinterCold: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#CDE8E5",
      paper: "#EEF7FF",
    },
    text: {
      primary: "#222222",
      secondary: "#707070",
    },
    primary: {
      main: "#4D869C",
    },
    secondary: {
      main: "#7AB2B2",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};

export const Aurora: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: "#2a3b4c",
      paper: "#243342",
    },
    text: {
      primary: "#f1f1f1",
      secondary: "#9fa8a3",
    },
    primary: {
      main: "#46b3e6",
    },
    secondary: {
      main: "#f9a825",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};

export const DesertMirage: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: "#3a2d2a",
      paper: "#2d1f1b",
    },
    text: {
      primary: "#f5f5f5",
      secondary: "#b0a999",
    },
    primary: {
      main: "#d4a373",
    },
    secondary: {
      main: "#6a994e",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};
