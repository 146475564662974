import React, { Fragment } from "react";
import { StyledPaper } from "../../components/styled-paper";
import { StyledAreaChart } from "../../../charts-library/area-chart";
import { useTheme } from "@mui/material";
import { IBucket } from "../../module.interface";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { selectedMember$, user$ } from "../../../state/user";
import { toast } from "../../../state/snackbar";
import { GetInventoryGrowthOvertime } from "../../../services/dashboard.service";
import useDeepCompareEffect from "use-deep-compare-effect";

export const CarsRecordsOvertime: React.FC = () => {
  const selectedMember = useBehaviourSubject(selectedMember$);
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IBucket[]>([]);
  const user = useBehaviourSubject(user$);
  const fetchData = () => {
    if (user) {
      setLoading(true);
      GetInventoryGrowthOvertime()
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {
          toast("Erorr happened while fetching top employees", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useDeepCompareEffect(fetchData, [user, selectedMember]);

  return (
    <Fragment>
      <StyledPaper
        title="Inventory Growth"
        subtitle="Overview of your cars added overtime"
      >
        <StyledAreaChart
          chartColors={[theme.palette.primary.main]}
          labels={data.map((i) => i.key)}
          chartSeries={[
            {
              name: "Cars Added",
              data: data.map((i) => i.doc_count),
              type: "area",
            },
          ]}
          loading={loading}
        />
      </StyledPaper>
    </Fragment>
  );
};
