import { BehaviorSubject } from "rxjs";
import { map as observableMap } from "rxjs/operators";
import { LocalStorageBehaviorSubject } from "./localstorage-behaviour-subject";

export const UserPreferences = new (class SettingsServiceMonolith {
  protected userPrefs$: BehaviorSubject<{ [key: string]: any }>;

  constructor() {
    this.userPrefs$ = new LocalStorageBehaviorSubject("user_preferences", {});
  }

  public local(settingName: string) {
    return this.userPrefs$.pipe(observableMap((val) => val[settingName]));
  }

  public setLocal(settingName: string, value: any) {
    const userPrefs = this.userPrefs$.getValue();
    userPrefs[settingName] = value;
    this.userPrefs$.next(userPrefs);
  }
})();
