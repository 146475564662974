import React, { Fragment, useEffect } from "react";
import "./App.css";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  Aurora,
  DefaultTheme,
  DesertMirage,
  OrangeTheme,
  WinterCold,
} from "./theme";
import { MasterView } from "./views/Master";
import { AuthenticationPage } from "./authentication/authentication.index";
import { SnackbarProvider } from "notistack";
import { Snackbar } from "./views/Snackbar";
import { has } from "lodash";
import { useObservable } from "./hooks/user-obsevrable";
import { UserPreferences } from "./views/user.preferences";

export const App: React.FC = () => {
  const userSelectedTheme = useObservable(
    UserPreferences.local("selected_theme")
  );

  const theme = createTheme(
    userSelectedTheme === "midnight-ember"
      ? DefaultTheme
      : userSelectedTheme === "winter-cold"
      ? WinterCold
      : userSelectedTheme === "aurora"
      ? Aurora
      : userSelectedTheme === "desert-mirage"
      ? DesertMirage
      : userSelectedTheme === "orange"
      ? OrangeTheme
      : DefaultTheme
  );

  useEffect(() => {
    const savedUserToken = JSON.parse(
      localStorage.getItem("logged_user_record") || "{}"
    );
    const tempUserToken = JSON.parse(
      sessionStorage.getItem("logged_user_record") || "{}"
    );

    if (
      !has(savedUserToken, "staff_email") &&
      !has(tempUserToken, "staff_email")
    ) {
      if (!window.location.pathname.includes("/auth")) {
        window.location.replace("/auth/login");
      }
    }
  }, []);
  if (window.location.pathname.includes("/auth")) {
    return (
      <Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={50}
              preventDuplicate
              autoHideDuration={3000}
            >
              <AuthenticationPage />
              <Snackbar />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={50}
              preventDuplicate
              autoHideDuration={3000}
            >
              <MasterView />
              <Snackbar />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Fragment>
    );
  }
};
