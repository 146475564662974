import React from "react";
import { GetTopMakesInventory } from "../../../services/dashboard.service";
import { IPieChart } from "../charts/charts.types";
import { PieChart} from "../charts/PieChart";

const PieChart1: IPieChart = {
    fetchFunction: GetTopMakesInventory,
    title: "Top 5 Car Makes",
    description: "Overview of your car makes",
    showDetails: true,
    detailsTitle: "Car Makes List",
    detialsSubTitle: "List of Car makes added to your inventory",
    colDefs: [
        {
            masterColumnTitle: "Make Name",
            masterColumnName: "doc_count",
            renderType: "text",
        },
        {
            masterColumnTitle: "Cars Added",
            masterColumnName: "doc_count",
            renderType: "text",
        },
    ],
}
export const TopMakesDonutChart: React.FC = () => {
    return (
        <PieChart
            {...PieChart1}
        />
    );
};
