import React, { Fragment, useEffect } from "react";
import { AppMasterTable } from "../../../components/app.master.table";
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { IMasterColumns, ITableFilters } from "../../module.interface";
import { IESDoc } from "../../../app.interface";
import { FetchPermissionsList } from "../../../services/settings.service";
import { toast } from "../../../state/snackbar";
import { AppDrawer } from "../../../views/Drawer";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { user$ } from "../../../state/user";
import { UUIDGenerator } from "../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { isPermissionValid } from "../../../util/user-permission-validator";
import { SelectionSearch } from "../../../components/app.selection_search";
import { CopiableText } from "../../../components/copiable_text";
import { InternalGetMembersData } from "../../../services/admin-panel/member.service";
import MemberDetail from "./views/detail.request.view";
import { CreateMemberController } from "./controllers/create.request.view";
import EditRequestRecord from "./controllers/edit.request.view";
import { InternalGetRequestsData } from "../../../services/admin-panel/internal.request.service";

export const InternalManageRequestsController: React.FC = () => {
  const module_permission = "inventory"; //TODO: change it to 'internal.requests'
  const [masterColumns] = React.useState<IMasterColumns[]>([
    {
      masterColumnName: "created_by",
      masterColumnTitle: "Created By",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText
          textToCopy={params?.created_by || "-"}
          title={"Created By"}
        >
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.created_by}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "module",
      masterColumnTitle: "Module",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.module || "-"} title={"Module"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params?.module || "-"}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "action",
      masterColumnTitle: "Action",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.action || "-"} title={"Action"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.action}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "status",
      masterColumnTitle: "Status",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.status || "-"} title={"Status"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.status}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "_id",
      masterColumnTitle: "",
      renderType: "text",
      cellRender: (params) => {
        return (
          <Box>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => {
                setSelectedUser(params);
              }}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [selectedSearchField, setSelectedSearchField] = React.useState<{
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
  }>({
    fieldName: "status",
    icon: "corporate_fare",
    title: "Status",
    hideOptions: false,
  });

  const [totalDocs] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("created_at");

  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [checkedDocs, setCheckedDocs] = React.useState<IESDoc[]>([]);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [selectedUser, setSelectedUser] = React.useState<IESDoc>();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [filterList] = React.useState<ITableFilters[]>([]);
  const [searchVal, setSearchVal] = React.useState<string>("");

  const [updateId, setUpdateId] = React.useState<string>("");
  const [permissionsStructureList, setPermissionsStructureList] =
    React.useState<string[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [addRequest, setAddRequest] = React.useState<boolean>(false);
  const user = useBehaviourSubject(user$);

  const fetchData = () => {
    if (user) {
      setLoading(true);
      InternalGetRequestsData(
        user.member_id,
        pageIndex,
        pageSize,
        searchQuery,
        undefined,
        undefined,
        undefined,
        sortField,
        sortOrder,
        filterList
      )
        .then((res) => {
          setDocs(res.data);
        })
        .catch(() => {
          toast(
            "Error happened while getting the users data, please try again later.",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchUserPermissionsData = () => {
    FetchPermissionsList(1000)
      .then((permissionsResponse) => {
        const initialData: IESDoc[] = permissionsResponse.data;
        setPermissionsStructureList(
          initialData.map((v) => {
            return v.name;
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching permissions", "error");
      });
  };
  useEffect(fetchUserPermissionsData, []);
  useDeepCompareEffect(fetchData, [
    updateId,
    searchQuery,
    pageSize,
    pageIndex,
    sortOrder,
    sortField,
    filterList,
    user,
  ]);
  const onCloseModel = () => {
    setSelectedDoc(undefined);
    setSelectedUser(undefined);
  };

  return (
    <Fragment>
      <Box className="header-container" sx={{ mb: 2 }}>
        <Stack spacing={2} sx={{ mt: 4 }}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                color={"primary"}
                sx={{ fontSize: 25, fontWeight: 600, textAlign: "center" }}
              >
                Request Manager
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ fontSize: 14, textAlign: "center", paddingTop: 0.5 }}
              >
                View and manage your Request.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xl={5} lg={5} md={9} sm={10} xs={12} sx={{ mb: 2 }}>
              <SelectionSearch
                rounded
                dataSource="ds_staff" //TODO: need to change
                availableSearchFields={[
                  {
                    fieldName: "status",
                    icon: "status", //TODO: choose correct icon
                    title: "Status",
                    hideOptions: false,
                  },
                ]}
                setSearchQuery={setSearchQuery}
                selectedSearchField={selectedSearchField}
                setSelectedSearchField={setSelectedSearchField}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box className="body-container">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* table */}
            <Paper sx={{ paddingTop: 1, mt: 1 }}>
              <Grid
                container
                justifyContent={"flex-end"}
                spacing={1}
                sx={{ paddingRight: 2 }}
              >
                <Grid item alignSelf={"center"}>
                  <Tooltip title="Refresh">
                    <IconButton
                      onClick={() => {
                        setUpdateId(UUIDGenerator());
                      }}
                    >
                      <Icon color="primary" style={{ fontSize: "22px" }}>
                        refresh
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Tooltip
                    title={
                      !isPermissionValid(`${module_permission}.add`)
                        ? "You dont have permission to add records, contact your system admin"
                        : "Add New Employee"
                    }
                  >
                    <span>
                      <Button
                        onClick={() => {
                          setAddRequest(true);
                        }}
                        disabled={
                          !isPermissionValid(`${module_permission}.add`)
                        }
                        variant="contained"
                        color="primary"
                      >
                        <Typography
                          sx={{ fontSize: 13, textTransform: "none" }}
                        >
                          Add Request
                        </Typography>
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 1 }} />

              <AppMasterTable
                loading={loading}
                dataSource={"requests"}
                name={"requests"}
                title={"requests"}
                colDefs={masterColumns}
                docs={docs}
                totalDocs={totalDocs}
                pageIndex={pageIndex}
                pageSize={pageSize}
                sortField={sortField}
                sortOrder={sortOrder}
                onChangeSortField={setSortField}
                onChangeSortOrder={setSortOrder}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                onChangeSelectedDoc={setSelectedDoc}
                checkedDocs={checkedDocs}
                enablePagination={true}
                enableCheckBox={true}
                enableSelection={true}
                enableBrandColum={false}
                onChangeCheckedDocs={setCheckedDocs}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* detail */}
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={`${selectedDoc.action} ${selectedDoc.module}`}
          subTitle={selectedDoc.created_by}
          onClose={onCloseModel}
        >
          <MemberDetail doc={selectedDoc} colDoc={masterColumns.slice(0, -1)} />
        </AppDrawer>
      ) : null}

      {selectedUser ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedUser.actin}
          subTitle={selectedUser.module}
          onClose={onCloseModel}
        >
          <EditRequestRecord
            doc={selectedUser}
            setUpdateID={setUpdateId}
            onCloseModel={onCloseModel}
            permissionsStructure={permissionsStructureList}
          />
        </AppDrawer>
      ) : null}

      {addRequest && isPermissionValid(`${module_permission}.add`) ? (
        <FullScreenModal
          isOpen={addRequest}
          onClose={() => {
            setAddRequest(false);
          }}
          width="lg"
          title="Create Request"
          subTitle="Create new request"
          content={
            <CreateMemberController
              onClose={() => {
                setAddRequest(false);
                setUpdateId(UUIDGenerator());
              }}
            />
          }
        />
      ) : null}
    </Fragment>
  );
};
