import {
  AppBar,
  Box,
  Divider,
  Grid,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment } from "react";
import { LogOffModel } from "./logout.model";
import { AppUserProfile } from "./UserProfile";
import { useBehaviourSubject } from "../hooks/user-obsevrable";
import { user$ } from "../state/user";
import { Link } from "react-router-dom";
import masterLogoWhite from "../assets/logo/logo.png";
import "./styles.css";
import { HeaderMemberSelector } from "./member/header.member-selector";
import { NotificationsHeader } from "./header-notifications/notifications-header";
export const Header: React.FC = () => {
  const [logoutModel, setLogoutModel] = React.useState<boolean>(false);
  const [selectedLogo] = React.useState<string>(masterLogoWhite);

  const theme = useTheme();

  const user = useBehaviourSubject(user$);

  return (
    <Fragment>
      <AppBar
        position="sticky"
        color="inherit"
        className="app-header-container"
      >
        <Toolbar>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item>
                  <Grid container spacing={1} wrap="nowrap" alignItems="center">
                    <Grid item>
                      <Link
                        to="/"
                        className="app-logo-container"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        <Box className="app-logo-wrapper">
                          <Grid container>
                            <Grid item alignSelf={"center"}>
                              <img
                                width={100}
                                height={70}
                                src={selectedLogo}
                                alt="scrap-logo"
                                className="logo-img"
                              />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                              <Typography
                                sx={{
                                  fontSize: 24,
                                  fontWeight: 700,
                                  letterSpacing: "1.5px",
                                }}
                              >
                                <span
                                  style={{ color: theme.palette.error.main }}
                                >
                                  S
                                </span>
                                crap
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Link>
                    </Grid>
                    {/* <Grid item alignSelf={"center"}>
                      <IconButton
                        className="menubar-icon"
                        aria-label="breadcrumbs-menu"
                        // onClick={() => setMenuStatus(!menuStatus)}
                        size="large"
                      >
                        <Icon>menu</Icon>
                      </IconButton>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item>search</Grid> */}
            <Grid item alignSelf={"center"}>
              <Grid container spacing={2}>
                <Grid item alignSelf={"center"}>
                  <HeaderMemberSelector />
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  sx={{
                    paddingRight: "0px !important",
                    paddingLeft: "10px !important",
                  }}
                >
                  <Divider orientation="vertical" sx={{ height: "40px" }} />
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  sx={{ paddingLeft: "4px !important" }}
                >
                  <NotificationsHeader />
                </Grid>
                <Grid
                  item
                  alignSelf={"center"}
                  sx={{ paddingLeft: "4px !important" }}
                >
                  <AppUserProfile />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {logoutModel && user ? (
        <LogOffModel user={user} onClose={() => setLogoutModel(false)} />
      ) : null}
    </Fragment>
  );
};
