import {
  Badge,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";

import { toast } from "../../../../state/snackbar";
import { UUIDGenerator } from "../../../../util/uuid.generator";
import { IESDoc } from "../../../../app.interface";
import { IModulePermission, IUserRole } from "../../settings.interface";
import ScrollContainer from "react-indiana-drag-scroll";
import "../../settings.styles.css";
import { GetUserPermissions } from "../../../../services/settings.service";

export const SetRolePermissions: React.FC<{
  roleName: string;
  permissionsStructureList: string[];
  resultedPermissions: string[];
  setResultedPermissions: React.Dispatch<React.SetStateAction<string[]>>;
  setRoleName?: React.Dispatch<React.SetStateAction<string>>;
  isCreating?: boolean;
  isUpdating?: boolean;
  isChangeRoleAllowed?: boolean;
  userRoles?: IUserRole[];
  setOldPermissionsList?: React.Dispatch<React.SetStateAction<string[]>>;
  oldPermissionsList?: string[];
  editView?: boolean;
  resultView?: boolean;
  miniView?: boolean;
}> = ({
  roleName,
  permissionsStructureList,
  resultedPermissions,
  setResultedPermissions,
  isUpdating,
  setRoleName,
  isChangeRoleAllowed,
  userRoles,
  setOldPermissionsList,
  editView = true,
  resultView = true,
  miniView = true,
}) => {
  const [modules, setModules] = React.useState<string[]>([]);
  const [selectedModule, setSelectedModule] = React.useState<string>("");
  const [resultedModules, setResultedModules] = React.useState<string[]>([]);

  const [fetchingRolePermissions, setFetchingRolePermissions] =
    React.useState<boolean>(false);
  const theme = useTheme();

  const getRolePermissions = () => {
    if (isChangeRoleAllowed && !_.isEmpty(roleName)) {
      setFetchingRolePermissions(true);
      GetUserPermissions(roleName)
        .then((response) => {
          const x: IESDoc[] = response.data;
          setResultedPermissions(x[0].default_permission);
          if (setOldPermissionsList) {
            setOldPermissionsList(x[0].default_permission);
          }
        })
        .catch(() => {
          toast("Error happened while fetching permissions", "error");
        })
        .finally(() => {
          setFetchingRolePermissions(false);
        });
    }
  };

  useEffect(() => {
    if (_.isEmpty(roleName) && !_.isUndefined(userRoles) && setRoleName) {
      setRoleName(userRoles[0].role);
    }
  }, [roleName, userRoles, setRoleName]);

  useEffect(getRolePermissions, [
    setResultedPermissions,
    setOldPermissionsList,
    roleName,
    isChangeRoleAllowed,
  ]);

  useEffect(() => {
    const mainList = permissionsStructureList;
    const m = _.uniq(
      mainList?.map((item) => {
        return item.split(".")[0];
      })
    );
    setModules(m);
    setSelectedModule(m[0]);
  }, [permissionsStructureList]);

  useEffect(() => {
    const m = _.uniq(
      resultedPermissions?.map((item) => {
        return item.split(".")[0];
      })
    );
    setResultedModules(m);
  }, [resultedPermissions]);

  const getModulePermissionsList = (
    module: string,
    permissions: string[]
  ): IModulePermission[] => {
    const permissionsList = permissionsStructureList?.filter(
      (i) => i.split(".")[0] === module
    );
    const actions = permissionsList?.map((item) => {
      return item.split(".")[1];
    });
    const paresedPermissions = actions?.map((action) => {
      let is_allowed = false;
      if (permissions?.includes(`${module}.${action}`)) {
        is_allowed = true;
      }
      return {
        permission: action,
        is_allowed: is_allowed,
        id: UUIDGenerator(),
      };
    });

    return paresedPermissions;
  };

  const handleChange = (e: any, permission: string) => {
    let x = [...resultedPermissions];
    if (e.target.checked === true) {
      x.push(`${selectedModule}.${permission}`);
    } else {
      x = x?.filter((i) => i !== `${selectedModule}.${permission}`);
    }

    setResultedPermissions(x);
  };

  const checkSelectedModulePermissions = (module: string): boolean => {
    const x = resultedPermissions?.filter((i) => i.split(".")[0] === module);
    if (x?.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Fragment>
      <Stack>
        {isUpdating ? null : (
          <Box sx={{ mb: 1 }}>
            <Grid container justifyContent="space-between">
              <Grid item alignSelf={"center"}>
                <Box
                  sx={
                    isChangeRoleAllowed
                      ? { display: "inline-flex", width: "291px" }
                      : { display: "flex" }
                  }
                >
                  <Typography
                    sx={
                      isChangeRoleAllowed
                        ? {
                            display: "inline-block",
                            paddingRight: 1,
                            width: "190px",
                            alignSelf: "center",
                          }
                        : { display: "inline", paddingRight: 1 }
                    }
                  >
                    Role Name :
                  </Typography>
                  {isChangeRoleAllowed ? (
                    <FormControl fullWidth size="small">
                      <Select
                        fullWidth
                        onChange={(e) => {
                          if (setRoleName) {
                            setRoleName(e.target.value);
                          }
                        }}
                        value={roleName}
                      >
                        {userRoles?.map((val) => {
                          return (
                            <MenuItem value={val.role} key={val.id}>
                              {_.startCase(val.role)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography
                      color={"primary"}
                      sx={{
                        display: "inline",
                        fontSize: miniView ? 13 : 16,
                        fontWeight: 600,
                      }}
                    >
                      {" " + roleName}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {miniView ? null : <Divider sx={{ my: 1 }} />}
        {fetchingRolePermissions ? (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Skeleton variant="rounded" height={364} />
            </Grid>
          </Grid>
        ) : (
          <Box className="modules-and-permissions-container">
            <Grid container spacing={3} justifyContent="space-between">
              {editView === true ? (
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <ScrollContainer
                    className="scroll-container modules-wrapper scrolling-wrapper "
                    hideScrollbars={false}
                  >
                    {modules?.map((module) => {
                      return (
                        <Box
                          className="module-container"
                          key={module}
                          style={
                            selectedModule === module
                              ? {
                                  borderColor: theme.palette.primary.main,
                                }
                              : undefined
                          }
                          onClick={() => {
                            setSelectedModule(module);
                          }}
                        >
                          <Badge
                            variant="dot"
                            color="primary"
                            invisible={checkSelectedModulePermissions(module)}
                          >
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: miniView ? 11 : 14,
                              }}
                              color={
                                selectedModule === module
                                  ? "primary"
                                  : undefined
                              }
                            >
                              {_.startCase(module)}
                            </Typography>
                          </Badge>
                        </Box>
                      );
                    })}
                  </ScrollContainer>
                </Grid>
              ) : null}

              <Grid
                item
                lg={editView === true ? 9 : 12}
                md={editView === true ? 9 : 12}
                sm={editView === true ? 9 : 12}
                xs={editView === true ? 9 : 12}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ width: "100%", height: "100%" }}
                >
                  {editView ? (
                    <Box
                      className="permission-container"
                      sx={{
                        width: resultView ? "50%" : "100%",
                        height: "100%",
                        padding: 1,
                      }}
                    >
                      <Stack>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            <Typography
                              color="primary"
                              sx={{
                                fontSize: miniView ? 14 : 16,
                                fontWeight: 600,
                              }}
                            >
                              Actions
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider sx={{ my: 1 }} />
                        <Grid
                          container
                          spacing={1}
                          className="scrolling-wrapper modules-wrapper"
                          sx={{
                            direction: "ltr !important",
                            maxHeight: "280px !important",
                          }}
                        >
                          {getModulePermissionsList(
                            selectedModule,
                            resultedPermissions
                          )?.map((val) => {
                            return (
                              <Grid
                                item
                                key={val.id}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                              >
                                <Paper
                                  sx={{
                                    backgroundColor:
                                      theme.palette.background.default,
                                    padding: 1,
                                    display: "flex",
                                  }}
                                >
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item alignSelf={"center"}>
                                      <FormControl
                                        size="small"
                                        component="fieldset"
                                        variant="standard"
                                      >
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={val.is_allowed}
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    val.permission
                                                  );
                                                }}
                                                name="ticket"
                                                size="small"
                                              />
                                            }
                                            label={
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: miniView ? 11 : 12,
                                                }}
                                              >
                                                {_.startCase(val.permission)}
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Stack>
                    </Box>
                  ) : null}

                  {resultView ? (
                    <Box
                      className="permission-container"
                      sx={{
                        width: editView ? "50%" : "100%",
                        height: "100%",
                        padding: 1,
                      }}
                    >
                      <Stack>
                        <Typography
                          color="secondary"
                          sx={{ fontSize: miniView ? 14 : 16, fontWeight: 600 }}
                        >
                          Selected Actions View
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Box
                          className="scrolling-wrapper modules-wrapper"
                          sx={{
                            direction: "ltr !important",
                            maxHeight: "280px !important",
                          }}
                        >
                          {resultedPermissions?.length > 0 ? (
                            <>
                              {resultedModules?.map((module) => {
                                const a = getModulePermissionsList(
                                  module,
                                  resultedPermissions
                                )?.filter((i) => i.is_allowed === true);
                                return (
                                  <ul
                                    style={{ paddingLeft: 2, marginTop: -2 }}
                                    key={module}
                                  >
                                    <li style={{ listStyleType: "none" }}>
                                      <Typography
                                        sx={{
                                          fontSize: miniView ? 12 : 15,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {_.startCase(module)}
                                      </Typography>
                                      <div className="parent-child-tree">
                                        <ul style={{ marginTop: 1 }}>
                                          {a?.map((val) => {
                                            return (
                                              <li key={val.id}>
                                                <Typography
                                                  sx={{
                                                    fontSize: miniView
                                                      ? 11
                                                      : 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {_.startCase(val.permission)}
                                                </Typography>
                                              </li>
                                            );
                                          })}{" "}
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                            </>
                          ) : null}
                        </Box>
                      </Stack>
                    </Box>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
    </Fragment>
  );
};
