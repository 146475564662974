import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IAppModules } from "./modules/module.interface";
import { receiveModules } from "./modules/module.register";

export const RootRoutes: React.FC = () => {
  const [modules] = React.useState<IAppModules[]>(receiveModules());

  // const [userType, setUserType] = React.useState<string>("manager");

  return (
    <Routes>
      <Route
        path="/"
        element={modules.length > 0 ? <Navigate to={modules[0]?.path} /> : null}
      />
      {modules.map(
        (module) =>
          !module.isInternal && (
            <Route
              key={module.name}
              path={module.path}
              element={<module.controller />}
            />
          )
      )}
      {modules.map(
        (module) =>
          !module.isInternal &&
          module.subModules.map((sub) => (
            <Route
              key={sub.name}
              path={sub.path}
              element={<sub.controller />}
            />
          ))
      )}

      {modules.map((module) => (
        <Route
          key={module.name}
          path={module.path}
          element={<module.controller />}
        >
          {module.subModules.map((sub) => (
            <Route
              key={sub.name}
              path={sub.path}
              element={<sub.controller />}
            />
          ))}
        </Route>
      ))}

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
