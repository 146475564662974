import mapValues from "lodash/mapValues";
import mapKeys from "lodash/mapKeys";
import {
  IESParsedMapping,
  IESQueryResponse,
  IQuery,
  IQueryBody,
} from "../app.interface";
import axios from "./axios.service";
export const esQuery = async <T>(
  index: string,
  query: IQuery = { query: { bool: {} } },
  memberIds?: any[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/search/${index}`, { query, memberIds });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const esMapping: (index: string) => Promise<IESParsedMapping> = async (
  index: string
) => {
  try {
    const response = await axios.post<IESParsedMapping>(`/mapping/${index}`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const termsAgg = async (
  index: string,
  field: string,
  query: IQueryBody = { bool: {} },
  maxSize: number
) => {
  const response = await esQuery(index, {
    aggs: { termCount: { terms: { field, size: maxSize } } },
    size: 0,
    query,
  });
  const buckets = response.aggs.termCount.buckets;

  const terms = mapValues(mapKeys(buckets, "key"), "doc_count");
  return terms;
};
