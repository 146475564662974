import axios from "axios";
import { IESQueryResponse } from "../app.interface";
import {
  IMember,
  IMembershipChildCreateRequest,
  IMembershipChildRequest,
} from "../modules/module.interface";
import { CreateRequestRecord } from "./request.service";

export const GetMemberShip = async (
  member_id: string
): Promise<IESQueryResponse<IMember>> => {
  try {
    const response = await axios.post("/members/get", {
      member_id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const CreateMembershipChildRequestRecord = async (
  membershipChildRequestRecord: IMembershipChildCreateRequest
) => {
  const { created_by, ...value } = membershipChildRequestRecord;
  console.log(created_by);
  const requestRecord: IMembershipChildRequest = {
    action: "Create",
    module: "membership",
    value,
    created_by: created_by || "XXXXXX",
  };
  return await CreateRequestRecord(requestRecord);
};
