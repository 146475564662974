import { Box, Icon, IconButton, Stack, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { toast } from "../state/snackbar";
interface ICopiableText {
  textToCopy: string;
  children: React.ReactNode;
  title?: string;
  IconStyle?: React.CSSProperties;
}
export const CopiableText: React.FC<ICopiableText> = ({
  children,
  IconStyle,
  textToCopy,
  title,
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Fragment>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Stack spacing={1} direction={"row"}>
          <Box alignSelf={"center"}>{children}</Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(textToCopy);
              toast(`${title ? `${title} ` : ""}Copied to clipboard`, "info");
            }}
            sx={{ width: "25px", height: "25px", alignSelf: "center" }}
          >
            <Icon
              sx={{ visibility: isHovered ? "visible" : "hidden" }}
              style={
                IconStyle
                  ? IconStyle
                  : { fontSize: "14px", color: theme.palette.info.main }
              }
            >
              content_copy
            </Icon>
          </IconButton>
        </Stack>
      </Box>
    </Fragment>
  );
};
