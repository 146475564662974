import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { TopEmployeesBarChart } from "./views/top-employees.view";
import { CarsRecordsOvertime } from "./views/cars-records-overtime";
import { TopMakesDonutChart } from "./views/top-makes.view";
// import { AdminPanel } from "../admin-panel/admin-panel.index";

export const DashboardModule: React.FC = () => {
  return (
    <Fragment>
      <Stack spacing={2}>
        <Box className="dashboard-header">
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography
                color="primary"
                sx={{ fontSize: 18, fontWeight: 600 }}
              >
                Good Morning Mohammed
              </Typography>
              <Typography color={"text.secondary"} sx={{ fontSize: 14 }}>
                This is an overview of your scrap summary
              </Typography>
            </Grid>
            <Grid item>
              <Button>Filters</Button>
            </Grid>
          </Grid>
        </Box>

        <Box className="dashboard-body">
          <Grid container spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <CarsRecordsOvertime />
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <TopEmployeesBarChart />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <TopMakesDonutChart />
            </Grid>
            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
              <AdminPanel />
            </Grid> */}
          </Grid>
        </Box>
      </Stack>
    </Fragment>
  );
};
