import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect, useLayoutEffect } from "react";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import uniq from "lodash/uniq";
import has from "lodash/has";
import { esQuery } from "../services/query.service";
import { toast } from "../state/snackbar";
import { selectedMember$ } from "../state/user";
import { useBehaviourSubject } from "../hooks/user-obsevrable";
import useDeepCompareEffect from "use-deep-compare-effect";

interface ISelectionSearch {
  setSearchQuery: (value: React.SetStateAction<string>) => void;
  setSelectedSearchField: (selectedSearchField: {
    fieldName: string;
    icon: string;
    title: string;
    hideOptions?: boolean;
  }) => void;
  selectedSearchField: {
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
  };

  availableSearchFields: {
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
    placeholders?: string;
  }[];

  dataSource: string;
  isBulkAllowed?: boolean;
  bulkSearchQuery?: {
    fieldName: string;
    values: string[];
  };
  rounded?: boolean;
  setBulkSearchQuery?: (
    bulkSearchQuery:
      | {
          fieldName: string;
          values: string[];
        }
      | undefined
  ) => void;
}

export const SelectionSearch: React.FC<ISelectionSearch> = ({
  setSearchQuery,
  setSelectedSearchField,
  selectedSearchField,
  availableSearchFields,
  dataSource,
  isBulkAllowed = false,
  bulkSearchQuery,
  setBulkSearchQuery,
  rounded = false,
}) => {
  const theme = useTheme();
  const { search } = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const selectedMember = useBehaviourSubject(selectedMember$);
  const [foundSearchValues, setFoundSearchValues] = React.useState<string[]>(
    []
  );

  const [searchVal, setSearchVal] = React.useState<string>(
    new URLSearchParams(search).get("searchQuery") || ""
  );
  const [actionsEl, setActionsEl] = React.useState<null | HTMLElement>(null);

  const [bulkSearchEl, setBulkSearchEl] = React.useState<null | HTMLElement>(
    null
  );
  const [bulkSearchVal, setBulkSearchVal] = React.useState<string>("");

  const [selectedBulkSearchField, setSelectedBulkSearchField] = React.useState<{
    fieldName: string;
    title: string;
    icon: string;
    placeholders?: string;
  }>(availableSearchFields[0]);

  const searchFieldRef = React.useRef<HTMLDivElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState<number>(800);

  const onSetSearchQuery = () => {
    const trimmedValue = searchVal.trim().replace(/\/(?=[^/]*$)/, "/");
    setSearchQuery(trimmedValue);
  };

  const fetchSearchValues = () => {
    if (selectedSearchField.hideOptions) {
      setFoundSearchValues([]);
      return;
    }

    setLoading(true);
    esQuery(dataSource, {
      size: 0,
      aggs: {
        VALUES: {
          terms: {
            field: selectedSearchField.fieldName,
            size: 1000,
          },
        },
      },
    })
      .then((response) => {
        setFoundSearchValues(
          response.aggs.VALUES.buckets.map((i: any) => i.key)
        );
      })
      .catch((err) => {
        toast("Error loading search options", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setBulkSearchEl(null);
    if (setBulkSearchQuery) {
      setBulkSearchQuery(undefined);
      setBulkSearchVal("");
      setSelectedBulkSearchField(availableSearchFields[0]);
    }
  };

  const applyBulkSearch = () => {
    if (setBulkSearchQuery && isEmpty(bulkSearchVal.trim())) {
      setBulkSearchQuery(undefined);
      return;
    }
    if (isBulkAllowed && setBulkSearchQuery) {
      const searchValues: string[] = uniq(
        bulkSearchVal.split("\n").map((value) => value.trim())
      ).filter(Boolean);
      setBulkSearchQuery({
        fieldName: selectedBulkSearchField.fieldName,
        values: searchValues,
      });

      setBulkSearchEl(null); //close bulk search pop up
    }
  };

  const handleOnBulkDelete = (index: number) => {
    if (bulkSearchQuery && setBulkSearchQuery) {
      const x: string[] = [...bulkSearchQuery.values];
      if (x.length === 1) {
        setBulkSearchQuery(undefined);
        return;
      }

      if (x?.[index]) {
        x.splice(index, 1);
        setBulkSearchQuery({
          fieldName: bulkSearchQuery.fieldName,
          values: x,
        });
      }
    }
  };

  const handleInputChange = (event: any, value: string, reason: string) => {
    event.stopPropagation();
    if (reason === "clear" && setBulkSearchQuery) {
      handleClear();
    }
  };

  useDeepCompareEffect(fetchSearchValues, [
    selectedSearchField,
    dataSource,
    selectedMember,
  ]);

  useLayoutEffect(() => {
    // re-size menu width based on the textfield width on screen size change
    const updateMenuWidth = () => {
      if (searchFieldRef.current) {
        setMenuWidth(searchFieldRef.current.offsetWidth);
      }
    };
    updateMenuWidth();

    window.addEventListener("resize", updateMenuWidth);
    return () => window.removeEventListener("resize", updateMenuWidth);
  }, []);

  useEffect(() => {
    // remove focus from autocomplete on close menu
    if (!bulkSearchEl) {
      const inputElement = document.getElementById(
        "dynamic-autocomplete-bulk-search-tags"
      );
      if (inputElement) {
        inputElement.blur();
      }
    }
  }, [bulkSearchEl]);

  return (
    <Fragment>
      {bulkSearchQuery && isBulkAllowed && !isEmpty(bulkSearchQuery.values) ? (
        <Stack
          direction={"row"}
          ref={searchFieldRef}
          onClick={(event) => {
            setBulkSearchEl(event.currentTarget);
          }}
        >
          <Button
            size="small"
            variant="text"
            color="secondary"
            className={
              theme.palette.mode === "dark"
                ? "search-field-btn-dark"
                : "search-field-btn-light"
            }
            sx={{
              paddingX: 3,
              borderRadius: "0px",
              border: "1px solid",
              borderColor:
                theme.palette.mode === "dark" ? "#ffffff3b" : "#0000003b",
              borderTopLeftRadius: rounded ? "30px" : "4px",
              borderBottomLeftRadius: rounded ? "30px" : "4px",
              textTransform: "none",
            }}
          >
            {selectedBulkSearchField.title}
          </Button>
          <Autocomplete
            id="dynamic-autocomplete-bulk-search-tags"
            size="small"
            fullWidth
            multiple
            options={[]}
            sx={{
              "& fieldset": {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: rounded ? "30px" : undefined,
                borderBottomRightRadius: rounded ? "30px" : undefined,
              },
              "& .MuiAutocomplete-popupIndicator": {
                display: "none",
              },
            }}
            // disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                id="outlined-basic"
                value={""}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                  "& .MuiInputBase-root": {
                    paddingRight: "30px !important",
                  },
                }}
                onClick={(event) => {
                  setBulkSearchEl(event.currentTarget);
                }}
              />
            )}
            limitTags={3}
            renderTags={(values: string[]) =>
              values.map((option, index: number) => (
                <Chip
                  color="primary"
                  icon={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "3px",
                        borderRadius: 100,
                        backgroundColor: alpha(theme.palette.primary.main, 0.2),
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: 12,
                          color: theme.palette.primary.main,
                        }}
                      >
                        {selectedBulkSearchField.icon}
                      </Icon>
                    </Box>
                  }
                  variant="outlined"
                  label={option}
                  key={option}
                  size="small"
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    maxWidth: "180px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    mr: "4px",
                    mb: "4px",
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    handleOnBulkDelete(index);
                  }}
                />
              ))
            }
            onInputChange={handleInputChange}
            value={bulkSearchQuery?.values || []}
            onClick={(event) => {
              setBulkSearchEl(event.currentTarget);
            }}
          />
        </Stack>
      ) : (
        <Stack direction={"row"} ref={searchFieldRef}>
          <Button
            size="small"
            variant="text"
            color="secondary"
            className={
              theme.palette.mode === "dark"
                ? "search-field-btn-dark"
                : "search-field-btn-light"
            }
            endIcon={
              <Icon>{isNull(actionsEl) ? "expand_more" : "expand_less"}</Icon>
            }
            sx={{
              paddingX: 3,
              borderRadius: "0px",
              border: "1px solid",
              borderColor:
                theme.palette.mode === "dark" ? "#ffffff3b" : "#0000003b",
              borderTopLeftRadius: rounded ? "30px" : "4px",
              borderBottomLeftRadius: rounded ? "30px" : "4px",
              textTransform: "none",
            }}
            onClick={(event) => setActionsEl(event.currentTarget)}
          >
            {selectedSearchField.title}
          </Button>
          <Autocomplete
            key={"dynamic-search"}
            id="dynamic-search"
            size="small"
            fullWidth
            getOptionLabel={(option) => option}
            sx={{
              "& fieldset": {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: rounded ? "30px" : undefined,
                borderBottomRightRadius: rounded ? "30px" : undefined,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                id="outlined-basic"
                placeholder={`Search For Specific ${selectedSearchField.title}`}
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                  "& .MuiInputBase-root": {
                    paddingRight: "30px !important",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading ? (
                        <CircularProgress size={16} />
                      ) : isBulkAllowed ? (
                        <Tooltip title="Bulk Search">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(event) => {
                              if (isNull(bulkSearchEl)) {
                                setBulkSearchEl(event.currentTarget);
                              } else {
                                setBulkSearchEl(null);
                              }
                            }}
                            sx={{ mr: -3 }}
                          >
                            <Icon style={{ fontSize: "20px" }}>
                              {isNull(bulkSearchEl)
                                ? "open_in_full"
                                : "close_fullscreen"}
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Icon style={{ fontSize: "20px" }} sx={{ mr: -3 }}>
                          search
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            freeSolo
            value={searchVal}
            onKeyDown={(ev) => (ev.key === "Enter" ? onSetSearchQuery() : null)}
            onChange={(e, v) => {
              if (v && !isNull(v)) {
                setSearchQuery(v);
              }
            }}
            noOptionsText={
              selectedSearchField.hideOptions
                ? "No Values Found"
                : `${selectedSearchField.title} Not Found`
            }
            options={foundSearchValues}
            loading={loading}
          />
        </Stack>
      )}

      <Menu
        id="actions-menu"
        anchorEl={actionsEl}
        open={Boolean(actionsEl)}
        onClose={() => setActionsEl(null)}
        slotProps={{
          paper: {
            style: {
              paddingTop: 0,
              border: `1px solid ${theme.palette.divider}`,
            },
          },
        }}
      >
        <Box>
          {availableSearchFields.map((item) => (
            <MenuItem
              key={item.fieldName}
              onClick={() => {
                setSelectedSearchField(item);
                setActionsEl(null);
              }}
              selected={item.fieldName == selectedSearchField.fieldName}
            >
              <Stack direction={"row"} spacing={2}>
                <Icon>{item.icon}</Icon>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  {item.title}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Box>
      </Menu>

      <Menu
        id="actions-menu"
        anchorEl={bulkSearchEl}
        open={Boolean(bulkSearchEl)}
        onClose={() => {
          setBulkSearchEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: 6,
              marginLeft: 6,
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
              minWidth: `${menuWidth}px`,
            },
          },
        }}
      >
        <Stack spacing={3} sx={{ paddingX: 3 }}>
          <Box>
            <Tabs
              value={selectedBulkSearchField.fieldName}
              onChange={(e, v) => {
                const x = availableSearchFields.find((a) => a.fieldName === v);
                if (x) {
                  setSelectedBulkSearchField(x);
                }
              }}
            >
              {availableSearchFields.map((item) => (
                <Tab
                  value={item.fieldName}
                  key={item.fieldName}
                  disableTouchRipple
                  label={
                    <Box>
                      <Box
                        sx={{
                          backgroundColor:
                            item.fieldName === selectedBulkSearchField.fieldName
                              ? alpha(theme.palette.primary.main, 0.1)
                              : undefined,
                          padding: 1,
                          borderRadius: "6px",
                          "&:hover": {
                            backgroundColor: alpha(
                              theme.palette.text.primary,
                              0.1
                            ), // Hover effect
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Icon style={{ fontSize: "18px" }}>{item.icon}</Icon>
                          <Typography
                            sx={{
                              fontSize: 11,
                              fontWeight: 600,
                              textTransform: "none",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  }
                />
              ))}
            </Tabs>
            <Divider />
          </Box>

          <TextField
            multiline
            fullWidth
            size="small"
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "13px",
                fontWeight: 500,
              },
            }}
            placeholder={`Add list of ${
              selectedBulkSearchField.title
            }s Separated by new line.${
              has(selectedBulkSearchField, "placeholders") &&
              !isEmpty(selectedBulkSearchField.placeholders)
                ? `\nExample:\n${selectedBulkSearchField?.placeholders}`
                : ""
            } `}
            value={bulkSearchVal}
            onChange={(e) => {
              setBulkSearchVal(e.target.value);
            }}
            rows={7}
            autoFocus
          />

          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button
                size="small"
                variant="text"
                color="primary"
                sx={{ fontSize: 11 }}
                onClick={handleClear}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                sx={{ fontSize: 11 }}
                onClick={() => {
                  applyBulkSearch();
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Menu>
    </Fragment>
  );
};
