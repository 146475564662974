import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";

interface IFullScreenModal {
  isOpen: boolean;
  onClose: () => void;
  width: "lg" | "md" | "sm" | "xs" | "xl";
  title?: string;
  subTitle?: string;
  content: ReactNode;
  actions?: ReactNode;
}

export const FullScreenModal: React.FC<IFullScreenModal> = ({
  isOpen,
  onClose,
  content,
  title,
  subTitle,
  actions,
  width,
}) => {
  return (
    <Fragment>
      {isOpen ? (
        <Dialog
          open={isOpen}
          onClose={() => {
            onClose();
          }}
          fullWidth
          maxWidth={width}
          aria-labelledby="modal-container"
          aria-describedby="full-screen-modal-container"
        >
          <DialogTitle>
            <Grid
              // sx={{ padding: 1 }}
              container
              justifyContent={"space-between"}
            >
              <Grid item alignSelf={"center"}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600 }}
                  color="primary"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 600 }}
                  color="text.secondary"
                >
                  {subTitle}
                </Typography>
              </Grid>
              <Grid item alignSelf={"center"}>
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <Icon color="primary">clear</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>{content}</DialogContent>

          {actions ? <DialogActions>{actions}</DialogActions> : null}
        </Dialog>
      ) : null}
    </Fragment>
  );
};
