import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Icon,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { DateRangeFilter } from "./date-range.filter";
import { IDateFilterObj } from "../../modules/module.interface";

interface IAppDateRangeSelector {
  selectedDate: IDateFilterObj;
  onChangeSelectedDate: (params: IDateFilterObj) => void;
  dateFieldOptions?: { name: string; title: string }[];
  dateField?: string;
  onChangeDateField?: (params: string) => void;
}
export const AppDateRangeSelector: React.FC<IAppDateRangeSelector> = ({
  selectedDate,
  onChangeSelectedDate,
  dateFieldOptions,
  dateField,
  onChangeDateField,
}) => {
  const [dateRange, setDateRange] = React.useState<HTMLButtonElement | null>(
    null
  );
  return (
    <Fragment>
      <Button
        onClick={(event) => setDateRange(event.currentTarget)}
        startIcon={<Icon>access_time</Icon>}
        variant="outlined"
        size="small"
      >
        <Typography variant="button" gutterBottom className="date-display">
          {selectedDate.mode === "quick"
            ? selectedDate.value
            : moment(selectedDate.startDate).format("MMMM Do YYYY") +
              " - " +
              moment(selectedDate.endDate).format("MMMM Do YYYY")}
        </Typography>
      </Button>
      <Popover
        id="date-range-popover"
        open={Boolean(dateRange)}
        anchorEl={dateRange}
        onClose={() => setDateRange(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          {dateField &&
          onChangeDateField &&
          dateFieldOptions &&
          !_.isEmpty(dateFieldOptions) ? (
            <Box>
              <Box style={{ padding: "12px 8px" }}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue={dateField}
                  onChange={(e, v) => onChangeDateField(v)}
                >
                  {dateFieldOptions.map((x) => (
                    <FormControlLabel
                      key={x.name}
                      value={x.name}
                      control={<Radio color="primary" />}
                      label={x.title}
                    />
                  ))}
                </RadioGroup>
              </Box>
              <Divider />
            </Box>
          ) : null}
          <DateRangeFilter
            currentDateObj={selectedDate}
            publishDateObj={(params) => {
              onChangeSelectedDate(params);
              setDateRange(null);
            }}
            onClose={() => setDateRange(null)}
          />
        </Box>
      </Popover>
    </Fragment>
  );
};
