import React, { Fragment } from "react";
import { UnderConstructionPage } from "../../modules/components/under-construction";

export const NotificationsView: React.FC = () => {
  return (
    <Fragment>
      <UnderConstructionPage height="60vh" width="40vw" />
    </Fragment>
  );
};
