import axios from "axios";
import { isUndefined } from "lodash";
import { ISelectedMember, IUserRecord } from "../state/user";

// import { AESEncryption } from "../util/md5.hash";
// import { logout } from "./auth.service";
// const userProfile = (): IUserProfile => {
//   const theme = getActiveTheme();
//   const sourceVersion = getSourceVersion();
//   const navigator = window.navigator;
//   const userAttributes = getUserAttributes();
//   return {
//     browser: navigator.vendor ? navigator.vendor : "Mozilla Firefox",
//     platform: navigator.platform,
//     code_version: sourceVersion,
//     user_theme: theme,
//     utc_offset: moment(new Date()).utcOffset(),
//     user_designation: userAttributes.designation,
//     user_organization: userAttributes.organization,
//   };
// };

const baseURL = process.env.REACT_APP_API_URL;
if (!baseURL) throw new Error("Required parameter APP_BASE_URL not set");
axios.defaults.baseURL = `${baseURL}/api`;
axios.interceptors.request.use(async (req) => {
  //   let AppKeyCloakToken = localStorage.getItem(
  //     `${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`
  //   );
  //   if (AppKeyCloak.isTokenExpired(60)) {
  //     await AppKeyCloak.updateToken(60);
  //     AppKeyCloakToken = AppKeyCloak.token
  //       ? AppKeyCloak.token
  //       : localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`);
  //   }
  //   req.headers.auth = AppKeyCloakToken;
  // AESEncryption(JSON.stringify(userProfile()))
  const loggedUser: IUserRecord = JSON.parse(
    localStorage.getItem("logged_user_record") ||
      sessionStorage.getItem("logged_user_record") ||
      "{}"
  );
  const selectedMember: ISelectedMember = JSON.parse(
    localStorage.getItem("selected_member") ||
      sessionStorage.getItem("selected_member") ||
      "{}"
  );
  const allowedMembers: number[] = JSON.parse(
    localStorage.getItem("allowed_members") ||
      sessionStorage.getItem("allowed_members") ||
      "{}"
  );

  let selectedMembers: number[] = selectedMember.is_admin_account
    ? allowedMembers
    : [selectedMember.member_id];

  const user = {
    member_id: selectedMembers || loggedUser.member_id,
    member_name: selectedMember.member_name,
    staff_email: loggedUser.staff_email,
    staff_name: loggedUser.staff_name,
    role_id: loggedUser.role_id,
  };

  req.headers.user = JSON.stringify(user);
  req.headers.isWeb = true;

  req.timeout = req.timeout ? req.timeout : 30 * 1000; // 30s
  //   let selectedBrands: number[] = [];
  //   let overrideBrands;
  //   try {
  //     if (req.data) {
  //       if (req.data.memberIds) {
  //         overrideBrands = req.data.memberIds;
  //       } else {
  //         overrideBrands = undefined;
  //       }
  //     }
  //   } catch (err) {
  //     selectedBrands = [];
  //     overrideBrands = [];
  //   }
  //   overrideBrands
  //     ? _.set(req, "data.memberIds", overrideBrands)
  //     : _.set(req, "data.memberIds", selectedBrands);
  return req;
});
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error) {
      if (error.response) {
        if (error.response.status === 401) {
          //   logout();

          console.log("LOGOUT !");
        }
      }
      // if (axios.isCancel(error)) {
      //   console.warn("Forcefully changed routing");
      // }
      if (error.isAxiosError) {
        return Promise.reject({ errors: error.response.data.errors });
      }
    }
  }
);
export default axios;
