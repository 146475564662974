import { IESDoc } from "../app.interface";
import { log } from "../util/log";
import {
  IMemberRequest,
  IRequestRecordBase,
  IRequestRecordKey,
} from "./../modules/module.interface";
import axios from "axios";
// TODO: fix type - to T
export const CreateRequestRecord: <T>(
  req: IRequestRecordBase<T>
) => Promise<any> = async (req) => {
  try {
    const response = await axios.post("/requests/add", req);
    log("CreateRequestRecord", { req, response });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const GetRequestRecordByKey: (
  req: IRequestRecordKey
) => Promise<any> = async (req) => {
  try {
    const response = await axios.post("/requests/get-by-key", req);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const UpdateRequestRecord: <T>(
  doc: IESDoc & IRequestRecordBase<T>
) => Promise<any> = async (doc: IESDoc) => {
  try {
    const response = await axios.post("/requests/update", {
      doc,
    });
    console.log(doc);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
