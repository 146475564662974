import { Icon, IconButton, Tooltip } from "@mui/material";
import React, { Fragment } from "react";
import { AppDrawer } from "../Drawer";
import { NotificationsView } from "./notifications-drawer.view";

export const NotificationsHeader: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <Fragment>
      <Tooltip title="Notifications" placement="bottom">
        <IconButton
          aria-label="account_circle"
          onClick={() => setIsOpen(true)}
          size="large"
        >
          <Icon>circle_notifications</Icon>
        </IconButton>
      </Tooltip>

      {isOpen ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={"Notifications"}
          subTitle={"Find out what is new"}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <NotificationsView />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
