import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { RootRoutes } from "../routes";
import { Header } from "./Header";
import { SideBar } from "./Sidebar";
import { BrowserRouter } from "react-router-dom";
import { useBehaviourSubject } from "../hooks/user-obsevrable";
import { user$ } from "../state/user";
import { UserInitalizer } from "./Initalizer";

const TOTAL_REQS = 2;
export const MasterView: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const [loadingCount, setLoadingCount] = React.useState<number>(TOTAL_REQS);

  return (
    <Fragment>
      {loadingCount > 0 ? (
        <UserInitalizer
          setLoadingCount={setLoadingCount}
          loadingCount={loadingCount}
          total_tasks={TOTAL_REQS}
        />
      ) : (
        <BrowserRouter>
          <Box className="app-master-container">
            <Header />
            <SideBar />
            <Box className="router-container">
              <RootRoutes />
            </Box>
          </Box>
        </BrowserRouter>
      )}
    </Fragment>
  );
};
