import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { strings } from "./membership.strings";
import { FieldCard } from "./components/field-card";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import { user$ } from "../../state/user";
import { IMember, IMemberEditRequest } from "../module.interface";
import { GetMemberShip } from "../../services/membership.service";
import { toast } from "../../state/snackbar";
import { LoadingButton } from "@mui/lab";
import _, { isNumber } from "lodash";
import { MembershipEditRequest } from "./controllers/membership.edit.request";
import { WritableCell } from "../_/components/writeable.cell";

type IMembershipDetails = IMember & {
  _id: string;
};
export const MembershipDetails: React.FC = () => {
  // Fetch Phase
  const user = useBehaviourSubject(user$);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [member, setMember] = React.useState<IMembershipDetails>();
  const fetchData = () => {
    if (user) {
      setLoading(true);
      GetMemberShip("8") //TODO: change it to user?.member_id
        .then((res) => {
          var m = res.data[0] as IMembershipDetails;
          if (m && m.childs_ids && m.childs_ids.length > 0) {
            m.childs_ids = m.childs_ids.map((c) => c.toString());
          }
          // console.log("M", m);
          setMember(m);
          setOldMember(m);
        })
        .catch(() => {
          toast(
            "Error happened while getting the users data, please try again later.",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useDeepCompareEffect(fetchData, [user]);

  // Edit Phase
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [oldMember, setOldMember] = React.useState<IMembershipDetails>();

  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);

  const handleClickEdit = () => {
    setIsEdit(!isEdit);
  };
  const handleChange = (e: any, field: string) => {
    const x = { ...member };
    switch (field) {
      case "member_name":
        x.member_name = e.target.value;
        break;
      case "email":
        x.email = e.target.value;
        break;
      case "address":
        x.address = e.target.value;
        break;
      case "latitude":
        if (x.location) x.location.latitude = e.target.value;
        break;
      case "longitude":
        if (x.location) x.location.longitude = e.target.value;
        break;
      case "instagram":
        if (x.socials) {
          x.socials.instagram = e.target.value;
        }
        break;
      case "whatsapp":
        if (x.socials) {
          x.socials.whatsapp = e.target.value;
        }
        break;
      case "phone_number":
        if (x.socials) {
          x.socials.phone_number = e.target.value;
        }
        break;
      case "category":
        x.category = e.target.value;
        break;
      case "type":
        x.type = e.target.value;
        break;
    }
    setMember(x as IMembershipDetails);
  };
  // TODO: on Submit
  const handleSubmit = () => {};
  useEffect(() => {
    if (!_.isEqual(member, oldMember)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [member, oldMember]);

  return (
    <Fragment>
      {/* Header */}
      <Box className="header-container" sx={{ mb: 2 }}>
        <Stack spacing={2} sx={{ mt: 4 }}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                color={"primary"}
                sx={{ fontSize: 25, fontWeight: 600, textAlign: "center" }}
              >
                {strings.title}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ fontSize: 14, textAlign: "center", paddingTop: 0.5 }}
              >
                {strings.description}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box>
        <Grid container spacing={2} justifyContent={"center"}>
          <MembershipEditRequest
            loading={loading}
            member={
              {
                ...member,
              } as IMemberEditRequest
            }
          />
          <Grid item lg={8} md={8} sm={8} xs={8} sx={{ mt: 5 }}>
            {/* Subtitle */}
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
              >
                Details
              </Typography>
              {/* Buttons */}
              {isEdit && (
                <Grid item>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        startIcon={<Icon>history</Icon>}
                        onClick={() => {
                          setMember(oldMember);
                        }}
                        disabled={!hasChanged || submitCounter}
                      >
                        Revert
                      </Button>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        loading={submitCounter}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!hasChanged}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                {!isEdit ? (
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    startIcon={<Icon>edit</Icon>}
                    onClick={handleClickEdit}
                    disabled={hasChanged}
                  >
                    Edit
                  </LoadingButton>
                ) : (
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    startIcon={<Icon>close</Icon>}
                    onClick={() => {
                      setMember(oldMember);
                      setIsEdit(false);
                    }}
                  >
                    Close
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          {/* Address */}
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <FieldCard
              title="Address"
              isDefault
              loading={loading}
              isEdit={isEdit}
              value={member?.address}
              onChange={(e) => {
                handleChange(e, "address");
              }}
            />
          </Grid>
          {/* Category */}
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <FieldCard
              title="Category"
              isDefault
              loading={loading}
              isEdit={isEdit}
              value={member?.category}
              onChange={(e) => {
                handleChange(e, "category");
              }}
            />
          </Grid>
          {/* Type */}
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <FieldCard
              title="Type"
              isDefault
              loading={loading}
              isEdit={isEdit}
              value={member?.type}
              onChange={(e) => {
                handleChange(e, "type");
              }}
            />
          </Grid>

          {/* Socials */}
          <>
            <Grid item lg={8} md={8} sm={8} xs={8} sx={{ mt: 3, mb: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
              >
                Social Media
              </Typography>
              <Divider />
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <FieldCard
                isDefault
                loading={loading}
                isEdit={isEdit}
                title={"Phone Number"}
                value={member?.socials?.phone_number}
                onChange={(e) => {
                  handleChange(e, "phone_number");
                }}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <FieldCard
                isDefault
                loading={loading}
                isEdit={isEdit}
                title={"Instagram"}
                value={member?.socials?.instagram}
                onChange={(e) => {
                  handleChange(e, "instagram");
                }}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <FieldCard
                isDefault
                loading={loading}
                isEdit={isEdit}
                title={"Whatsapp"}
                value={member?.socials?.whatsapp}
                onChange={(e) => {
                  handleChange(e, "whatsapp");
                }}
              />
            </Grid>
          </>
          {/* Location */}
          {member?.location && (
            <>
              <Grid item lg={8} md={8} sm={8} xs={8} sx={{ mt: 3, mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
                >
                  Location
                </Typography>
                <Divider />
              </Grid>

              <Grid item lg={8} md={8} sm={8} xs={8}>
                <FieldCard
                  title={"Latitude"}
                  type="number"
                  isDefault
                  loading={loading}
                  isEdit={isEdit}
                  value={member?.location.latitude}
                  onChange={(e) => {
                    handleChange(e, "latitude");
                  }}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <FieldCard
                  type="number"
                  title={"Longitude"}
                  isDefault
                  loading={loading}
                  isEdit={isEdit}
                  value={member?.location.longitude}
                  onChange={(e) => {
                    handleChange(e, "longitude");
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};
