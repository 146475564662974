import React, { Fragment, useEffect } from "react";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import {
  ISelectedMember,
  allowedMembers$,
  selectedMember$,
  user$,
} from "../../state/user";
import {
  Autocomplete,
  Box,
  Icon,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { has, isEmpty, isNull, toNumber } from "lodash";
import { FetchAllowedMemberData } from "../../services/user.service";
import { IESDoc } from "../../app.interface";
import { toast } from "../../state/snackbar";
import useDeepCompareEffect from "use-deep-compare-effect";

export const HeaderMemberSelector: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const allowedMemberIds = useBehaviourSubject(allowedMembers$);
  const selectedMember = useBehaviourSubject(selectedMember$);
  const [docs, setDocs] = React.useState<ISelectedMember[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const theme = useTheme();

  const fetchData = () => {
    if (user && user.is_admin_account) {
      setLoading(true);
      FetchAllowedMemberData()
        .then((res) => {
          const docsList: ISelectedMember[] = [
            {
              member_id: toNumber(user.member_id),
              member_name: user.member_name,
              is_admin_account: true,
            },
            ...res.data.map((org: IESDoc) => ({
              member_id: org.member_id,
              member_name: org.member_name,
              is_admin_account: false,
            })),
          ];
          setDocs(docsList);
        })
        .catch(() => {
          toast("Error happened while fetching members data", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useDeepCompareEffect(fetchData, [user, selectedMember]);

  console.log({ selectedMember });
  console.log({ docs });
  return (
    <Fragment>
      <Stack direction="row" spacing={1}>
        {user?.is_admin_account === true ? (
          loading ? (
            <Skeleton
              width={350}
              height={40}
              sx={{ alignSelf: "center" }}
              variant="rounded"
            />
          ) : (
            <Box sx={{ width: "350px" }}>
              <Autocomplete
                id="member-selector"
                options={docs}
                getOptionLabel={(op) => op.member_name}
                size="small"
                sx={{ alignSelf: "center", cursor: "pointer", height: "40px" }}
                isOptionEqualToValue={(option, value) =>
                  option.member_id === value.member_id
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        fontWeight: 500,
                      },
                      "& .MuiInputBase-root": {
                        paddingRight: "30px !important",
                        height: "40px",
                      },
                      cursor: "pointer",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                disableClearable
                renderOption={(props, option) => (
                  <li {...props} key={option.member_id}>
                    <Box className="member-list-render">
                      <Stack spacing={0.5} direction={"row"}>
                        {option.is_admin_account ? (
                          <Icon
                            style={{
                              fontSize: 16,
                              alignSelf: "center",
                              marginTop: -2,
                            }}
                            color="success"
                          >
                            verified
                          </Icon>
                        ) : null}
                        <Typography
                          color="text.primary"
                          component="p"
                          className="member-list-position"
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option.member_name}
                        </Typography>
                      </Stack>
                    </Box>
                  </li>
                )}
                onChange={(e, v) => {
                  if (v) {
                    selectedMember$.next(v);
                  }
                }}
                value={
                  !isNull(selectedMember)
                    ? selectedMember
                    : {
                        member_id: toNumber(user.member_id),
                        member_name: user.member_name,
                        is_admin_account: user.is_admin_account,
                      }
                }
                loading={loading}
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
              backgroundColor: alpha(theme.palette.info.main, 0.2),
              borderRadius: 1,
              cursor: "default",
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                color: theme.palette.info.main,
              }}
            >
              {user && has(user, "member_name")
                ? user.member_name
                : "No Member Found!"}
            </Typography>
          </Box>
        )}

        {user?.is_admin_account === true ? (
          <Tooltip title="You are logged in as an Admin for this orgagnization">
            <Box
              sx={{
                paddingX: 2,
                paddingY: 1,
                backgroundColor: alpha(theme.palette.success.main, 0.2),
                borderRadius: 1,
                cursor: "default",
              }}
            >
              <Stack direction={"row"} spacing={1}>
                <Icon color="success">verified</Icon>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: theme.palette.success.main,
                  }}
                >
                  Admin
                </Typography>
              </Stack>
            </Box>
          </Tooltip>
        ) : null}
      </Stack>
    </Fragment>
  );
};
