import React, { Fragment, useEffect } from "react";
import {
  Button,
  Grid,
  Icon,
  Typography,
  Paper,
  Fab,
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Stack,
} from "@mui/material";
import { IESDoc, IESQueryResponse } from "../../app.interface";
import { AppMasterTable } from "../../components/app.master.table";
import {
  IDateFilterObj,
  IFilterCategory,
  IMasterColumns,
  ISearchField,
  ISection,
  ITableFilters,
  ListProps,
} from "../module.interface";
import { FullScreenModal } from "../../components/app.full-screen.modal";
import { toast } from "../../state/snackbar";
import { setInitialDateRanges } from "../../util/date-range";
import { AppDateRangeSelector } from "../../components/date-range/app.date-range";
import { get, has } from "lodash";
import { UUIDGenerator } from "../../util/uuid.generator";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import { user$ } from "../../state/user";
import { isPermissionValid } from "../../util/user-permission-validator";
import { AppFilterView } from "../views/filter.view";
import { SelectionSearch } from "../../components/app.selection_search";
import { AppFiltersChips } from "../../components/app.filter_chips";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AppDrawer } from "../../views/Drawer";
import IDetail from "./views/_.detial.modal";
import { EditEntity } from "./views/_.edit.modal";
import { CreateEntity } from "./views/_.create.modal";

interface IBase<T> {
  module_permission: string;
  fieldDefinitions: ISection[];
  searchFields: ISearchField[];
  filtersCategories: IFilterCategory[];
  tableColumns: IMasterColumns[];
  initialData: T;
  listEntity: (entity: ListProps) => Promise<IESQueryResponse<T>>;
  createEntity: (entity: T) => Promise<void>;
  updateEntity: (entity: T) => Promise<void>;
  deleteEntity: (entity: T) => Promise<void>;
  strings: { title: string; description: string; addFab: string };
}

export const Base = <T extends {}>({
  module_permission,
  fieldDefinitions,
  searchFields,
  filtersCategories: filtersCategory,
  tableColumns,
  initialData,
  listEntity,
  createEntity,
  updateEntity,
  deleteEntity,
  strings,
}: IBase<T>) => {
  const user = useBehaviourSubject(user$);
  const theme = useTheme();
  // detail - edit - add
  const [selectedDocDetail, setSelectedDocDetail] = React.useState<IESDoc<T>>();
  type IEditDoc = T & { is_deleted: boolean };
  const [selectedDocEdit, setSelectedDocEdit] =
    React.useState<IESDoc<IEditDoc>>();
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  // list call
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("last_update");
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  // list call and table attr
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IESDoc<T>[]>([]);
  const [selectedDateRange, setSelectedDateRange] =
    React.useState<IDateFilterObj>(setInitialDateRanges());
  // table attr
  const [checkedDocs, setCheckedDocs] = React.useState<IESDoc<T>[]>([]);
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [updateId, setUpdateId] = React.useState<string>("");
  // filter
  const [filterList, setFilterList] = React.useState<ITableFilters[]>([]);
  // search
  const [searchVal, setSearchVal] = React.useState<string>("");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const onSetSearchQuery = () => {
    setSearchQuery(searchVal.trim().replace(/\/(?=[^/]*$)/, "/"));
  };
  const [selectedSearchField, setSelectedSearchField] =
    React.useState<ISearchField>(searchFields[0]);

  const masterColumns: IMasterColumns[] = [
    ...tableColumns,
    {
      masterColumnName: "_id",
      masterColumnTitle: "",
      renderType: "text",
      cellRender: (params) => {
        return (
          <Box>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => {
                setSelectedDocEdit(params as IESDoc<IEditDoc>);
              }}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ];

  const onCloseModel = () => {
    setSelectedDocEdit(undefined);
    setSelectedDocDetail(undefined);
  };

  // TODO: change name
  const fetchInventoryData = () => {
    if (user) {
      setLoading(true);
      const listProps: ListProps = {
        member_id: user.member_id,
        pageIndex,
        pageSize,
        searchQuery,
        startDate: selectedDateRange.status
          ? (selectedDateRange.startDate as number)
          : undefined,
        endDate: selectedDateRange.status
          ? (selectedDateRange.endDate as number)
          : undefined,
        sortField,
        sortOrder,
        filters: filterList,
      };
      listEntity(listProps)
        .then((res) => {
          setData(
            res.data.map((i) => {
              const x = { ...i };
              // if ("default_image" in x && "part_name" in x)
              //   x.default_image = GetCarPartImageName(x.part_name);
              return x;
            })
          );
          setTotalDocs(
            has(res, "metadata.total.value")
              ? (get(res, "metadata.total.value") as unknown as number)
              : 0
          );
        })
        .catch(() => {
          toast("Error happened while fetching your inventory data", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const fillCallableArrayField = async () => {
      fieldDefinitions
        .flatMap((section) => section.fields)
        .map(async (field) => {
          if (field.selectable) {
            if (field.selectable.populateArrayCall) {
              const populateArrayResponse =
                await field.selectable.populateArrayCall();
              field.selectable.array = populateArrayResponse.data;
            }
          }
        });
    };
    // TODO: run in the background
    fillCallableArrayField();
  }, [fieldDefinitions]);

  useDeepCompareEffect(fetchInventoryData, [
    pageIndex,
    pageSize,
    searchQuery,
    selectedDateRange,
    sortOrder,
    sortField,
    updateId,
    user,
    filterList,
  ]);

  return (
    <Fragment>
      <Box
        className="tab-container"
        sx={{ position: "relative", minHeight: "80vh" }}
      >
        <Fab
          variant="extended"
          color="primary"
          sx={{ position: "fixed", right: 60, bottom: 60 }}
          onClick={() => {
            setShowAddModal(true);
          }}
        >
          <Icon sx={{ mr: 1 }}>add</Icon>
          {strings.addFab}
        </Fab>

        <Stack spacing={2}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                color={"primary"}
                sx={{ fontSize: 25, fontWeight: 600, textAlign: "center" }}
              >
                {strings.title}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ fontSize: 14, textAlign: "center", paddingTop: 0.5 }}
              >
                {strings.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xl={5} lg={5} md={9} sm={10} xs={12} sx={{ mb: 2 }}>
              <SelectionSearch
                rounded
                //TODO: pass dataSource
                dataSource="ds_feed_inventory_parts"
                availableSearchFields={searchFields}
                setSearchQuery={setSearchQuery}
                selectedSearchField={selectedSearchField}
                setSelectedSearchField={setSelectedSearchField}
              />
            </Grid>
          </Grid>
        </Stack>

        <Grid container justifyContent={"space-between"} sx={{ mt: 2, mb: 1 }}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <AppFiltersChips
              currentFilters={filterList}
              //TODO: pass dataSource
              dataSource="ds_feed_inventory_parts"
              filtersCategories={filtersCategory}
              onChangeFilters={setFilterList}
              setSelectedDateRange={setSelectedDateRange}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1} justifyContent={"flex-end"}>
              <Grid item alignSelf={"center"}>
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={() => {
                      setUpdateId(UUIDGenerator());
                    }}
                  >
                    <Icon color="primary">refresh</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <AppDateRangeSelector
                  selectedDate={selectedDateRange}
                  onChangeSelectedDate={setSelectedDateRange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AppFilterView
              filters={filterList}
              publishFilters={setFilterList}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper sx={{ paddingTop: 1, mt: 1 }}>
              <AppMasterTable
                loading={loading}
                //TODO: pass dataSource
                dataSource={"ds_feed_inventory_parts"}
                name={"ds_feed_inventory_parts"}
                title={"ds_feed_inventory_parts"}
                colDefs={masterColumns}
                docs={data as IESDoc[]}
                totalDocs={totalDocs}
                pageIndex={pageIndex}
                pageSize={pageSize}
                sortField={sortField}
                sortOrder={sortOrder}
                onChangeSortField={setSortField}
                onChangeSortOrder={setSortOrder}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                enablePagination={true}
                enableCheckBox={false}
                enableSelection={true}
                enableBrandColum={false}
                checkedDocs={checkedDocs as IESDoc[]}
                onChangeCheckedDocs={setCheckedDocs as (docs: IESDoc[]) => void}
                onChangeSelectedDoc={
                  setSelectedDocDetail as (doc: IESDoc | undefined) => void
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {showAddModal && isPermissionValid(`${module_permission}.add`) ? (
        <FullScreenModal
          isOpen={showAddModal}
          onClose={() => {
            setShowAddModal(false);
          }}
          width="lg"
          title="Create Member"
          subTitle="Create new member"
          content={
            <CreateEntity<T>
              onClose={onCloseModel}
              fieldDelineations={fieldDefinitions}
              initialData={initialData}
              createEntity={createEntity}
            />
          }
        />
      ) : null}

      {/* edit */}
      {selectedDocEdit && isPermissionValid(`${module_permission}.edit`) && (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedDocEdit.member_id}
          subTitle={selectedDocEdit.created_at}
          onClose={onCloseModel}
        >
          <EditEntity
            doc={selectedDocEdit}
            fieldDefinitions={fieldDefinitions}
            setUpdateID={setUpdateId}
            onCloseModel={onCloseModel}
            deleteEntity={deleteEntity}
            updateEntity={updateEntity}
          />
        </AppDrawer>
      )}

      {/* detail */}
      {selectedDocDetail ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedDocDetail.part_name}
          subTitle={selectedDocDetail.part_id}
          onClose={onCloseModel}
        >
          <IDetail
            doc={selectedDocDetail}
            fieldDefinitions={fieldDefinitions}
          />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
