import { Skeleton, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";

interface IStyledAreaChart {
  loading: boolean;
  labels: string[];
  chartColors: string[];
  chartSeries: {
    name: string;
    data: number[];
    type: string;
  }[];
  containerHeight?: number;
}

export const StyledAreaChart: React.FC<IStyledAreaChart> = ({
  loading,
  labels,
  chartColors,
  chartSeries,
  containerHeight = 300,
}) => {
  const theme = useTheme();

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      type: "area",
      stacked: false,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2,
        animateGradually: {
          enabled: true,
          delay: 1,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    // theme: {
    //   palette: "palette3",
    // },
    fill: {
      opacity: 0.7,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.8,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        inverseColors: true,
        stops: [0, 100],
      },
    },
    colors: chartColors,

    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: "datetime",
      categories: labels,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },

      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontSize: "12px",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
    },
    grid: {
      show: false,
      row: {
        colors: [
          theme.palette.background.paper,
          theme.palette.background.default,
        ],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      fillSeriesColor: true,
      x: {
        show: false,
      },
    },

    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "10px",
      offsetY: 7,
      labels: {
        colors: theme.palette.text.secondary,
      },
    },
  };

  return (
    <Fragment>
      <ResponsiveContainer height={containerHeight}>
        {loading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} />
        )}
      </ResponsiveContainer>
    </Fragment>
  );
};
