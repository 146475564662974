import { Box, Button, Container, IconButton } from "@mui/material";
import { WritableImageCell } from "./writable.cell.image";
import { useState } from "react";
import {
  AddPhotoAlternate,
  ArrowBack,
  ArrowForward,
  Delete,
} from "@mui/icons-material";
import { IWritableCell } from "../../_.types";
import { toast } from "../../../../state/snackbar";

export const WritableImageArrayCell: React.FC<IWritableCell> = (props) => {
  const { entity, masterColumnName, handleChange } = props;

  const initialImages = entity[masterColumnName];
  // const initialImages = Array.from(
  //   { length: 5 },
  //   (_, i) => `${GetCarPartImageName(entity.part_name)}`
  // );
  // console.log(initialImages);
  const [images, setImages] = useState<string[]>(initialImages || [""]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  const handleAddImage = () => {
    const emptyImageIndex = images.findIndex((image) => image === "");
    if (emptyImageIndex > -1) {
      setCurrentIndex(emptyImageIndex);
      toast("Upload Your new image here first, then you can add more");
      return;
    }
    var imagesList = [...images];
    imagesList.push("");
    setImages(imagesList);
    setCurrentIndex(imagesList.length - 1);
  };
  // this should be same as Writable handleChange,
  // cuz it will be passed to writableImage
  const handleSingleImageChange: (
    imageBase64: any,
    indexString: string
  ) => void = (imageBase64, indexString) => {
    var imagesList = [...images];
    const index = parseInt(indexString);
    const newImage = imageBase64 as string;
    imagesList[index] = newImage;
    setImages(imagesList);
    handleChange(imagesList, masterColumnName);
  };
  const handleDeleteImage = () => {
    if (images.length < 2) return;
    const i = currentIndex;
    handlePrev();
    var imagesList = [...images];
    imagesList.splice(i, 1);
    setImages(imagesList);
    handleChange(imagesList, masterColumnName);
  };

  return (
    <Container>
      <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            transition: "transform 0.5s ease",
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {images.map((item, i) => (
            <Box key={i} sx={{ minWidth: "100%", padding: 2 }}>
              <WritableImageCell
                {...props}
                initialImagePath={item}
                handleChange={handleSingleImageChange}
                masterColumnName={i.toString()}
              />
            </Box>
          ))}
        </Box>
        <IconButton
          onClick={handleAddImage}
          sx={{
            backgroundColor: "green",
            position: "absolute",
            top: "30%",
            right: 0,
            zIndex: 1,
          }}
        >
          <AddPhotoAlternate />
        </IconButton>
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handleDeleteImage}
              sx={{
                backgroundColor: "red",
                position: "absolute",
                top: "70%",
                right: 0,
                zIndex: 1,
              }}
            >
              <Delete />
            </IconButton>
            <IconButton
              onClick={handlePrev}
              sx={{ position: "absolute", top: "50%", left: 0, zIndex: 1 }}
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{ position: "absolute", top: "50%", right: 0, zIndex: 1 }}
            >
              <ArrowForward />
            </IconButton>
          </>
        )}
      </Box>
    </Container>
  );
};
