import {
  Box,
  Icon,
  Paper,
  Skeleton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { range } from "lodash";
import React from "react";

export const PaperTiledWithIcon: React.FC<{
  title: string;
  value: string;
  loading: boolean;
  color: "primary" | "secondary" | "info" | "error" | "success" | "warning";
  icon: string;
  isDefault: boolean;
  isMasked?: boolean;
}> = ({ title, value, loading, color, icon, isDefault, isMasked }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: isDefault
          ? theme.palette.background.default
          : theme.palette.background.paper,
        padding: 2,
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={45} />
      ) : (
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Box sx={{ maxWidth: "70%" }}>
            <Stack>
              <Typography
                color={color}
                sx={{
                  fontSize: 11,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </Typography>
              {isMasked ? (
                <Stack direction="row" sx={{ mt: 1 }}>
                  {range(0, value.length < 8 ? value.length : 8).map(() => (
                    <Icon
                      style={{ fontSize: 9, color: theme.palette.grey[500] }}
                    >
                      circle
                    </Icon>
                  ))}
                </Stack>
              ) : (
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  {value}
                </Typography>
              )}
            </Stack>
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <Box
              sx={{
                bgcolor: alpha(theme.palette[color].main, 0.2),
                width: 40,
                height: 40,
                borderRadius: 100,
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              <Icon color={color} sx={{ mt: "20%" }}>
                {icon}
              </Icon>
            </Box>
          </Box>
        </Stack>
      )}
    </Paper>
  );
};
