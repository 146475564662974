import { has, isNull, isUndefined } from "lodash";
import { IUser } from "../modules/module.interface";
import { IUserRecord } from "../state/user";

export const isPermissionValid = (permission: string): boolean => {
  let isValid = false;
  const userToken: IUserRecord = JSON.parse(
    localStorage.getItem("logged_user_record") ||
      sessionStorage.getItem("logged_user_record") ||
      "{}"
  );

  if (!isUndefined(userToken) && has(userToken, "permissions_list")) {
    if (userToken.is_admin_account) {
      return true;
    }

    if (userToken.permissions_list.includes(permission)) {
      return true;
    }
  }
  return isValid;
};
