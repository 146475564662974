import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { ManageUsersController } from "./manage_users/manage_users.index";
// TODO: What is the point of this
export const SettingsModuleController: React.FC = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/settings" element={<ManageUsersController />}>
          <Route path="/users" element={<ManageUsersController />} />
        </Route>
      </Routes>
    </Fragment>
  );
};
