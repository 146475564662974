import _ from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import { IWritableCell } from "../../_.types";
import { toast } from "../../../../state/snackbar";
import { FullScreenModal } from "../../../../components/app.full-screen.modal";
import { isPermissionValid } from "../../../../util/user-permission-validator";
import { CreateEntity } from "../../views/_.create.modal";
import { IAddSelectable } from "../../../module.interface";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const WritableSelectableText: React.FC<IWritableCell> = (props) => {
  const {
    entity,
    handleChange,
    masterColumnName,
    masterColumnTitle,
    selectable,
    required,
    immutable,
  } = props;
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  if (!selectable) return <></>;
  const { array, labelField, onChange, multiSelect, AddSelectable } =
    selectable;
  const value = _.get(entity, masterColumnName);
  // Only matter if AddSelectable is provided
  const onClose = () => {
    setShowAddModal(false);
  };

  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12} key={masterColumnName}>
        <Autocomplete
          disabled={immutable}
          multiple={multiSelect}
          disableClearable={required}
          options={array || []}
          value={
            multiSelect
              ? value || []
              : array &&
                array.find(
                  (selectObj) => selectObj == _.get(entity, masterColumnName)
                )
          }
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={
            multiSelect
              ? (props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  );
                }
              : undefined
          }
          onChange={(e, selectObj) => {
            handleChange(selectObj, masterColumnName);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={masterColumnTitle}
              placeholder="Select Childs"
            />
          )}
          PaperComponent={
            AddSelectable
              ? ({ children }) => {
                  return (
                    <Paper>
                      {children}
                      <Button
                        color="primary"
                        fullWidth
                        sx={{
                          justifyContent: "flex-start",
                          pl: 2,
                        }}
                        onMouseDown={() => {
                          setShowAddModal(true);
                        }}
                      >
                        + Add New
                      </Button>
                    </Paper>
                  );
                }
              : undefined
          }
        />
      </Grid>

      {AddSelectable &&
      isPermissionValid(`${AddSelectable.module_permission}.add`) ? (
        <CreateEntityModal
          {...AddSelectable}
          showAddModal={showAddModal}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

type IAddSelectableView = IAddSelectable & {
  showAddModal: boolean;
  onClose: () => void;
};
const CreateEntityModal: React.FC<IAddSelectableView> = (props) => {
  const {
    fieldDelineations,
    initialData,
    createEntity,
    onClose,
    titleModal,
    subTitleModal,
    showAddModal,
  } = props;
  return (
    <>
      <FullScreenModal
        isOpen={showAddModal}
        onClose={onClose}
        width="lg"
        title={titleModal}
        subTitle={subTitleModal}
        content={
          <CreateEntity<any>
            onClose={onClose}
            fieldDelineations={fieldDelineations}
            initialData={initialData}
            createEntity={createEntity}
            appendMemberDetailsToEntity={false}
          />
        }
      />
    </>
  );
};
