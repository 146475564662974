import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "react-lottie";

import animationData from "../assets/lottie/empty-search.json";

export const EmptyTableView: React.FC = () => {
  return (
    <Fragment>
      <Box className="no-rows-container">
        <Box>
          <Box className="icon">
            <Lottie
              options={{
                animationData: animationData,
                loop: true,
                autoplay: true,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              width={210}
              height={210}
            />
          </Box>
          <Box
            className="content"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ textAlign: "center" }}
              color={"primary"}
            >
              No Results Found
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
