import React, { Fragment } from "react";
import { IESDoc } from "../../../../app.interface";
import { Box } from "@mui/material";
import { AppAssetsSectionBox } from "../../../../views/app.section.view";
import { AppDocView } from "../../../../views/app.doc.view";
import _ from "lodash";
import { IMasterColumns } from "../../../module.interface";

const MemberDetail: React.FC<{
  doc: IESDoc;
  colDoc: IMasterColumns[];
}> = ({ doc, colDoc }) => {
  const [views] = React.useState<string[]>(["Details"]);
  const [selectedView] = React.useState(views[0]);

  return (
    <Fragment>
      <Box>
        <Fragment>
          <AppAssetsSectionBox title={_.startCase(selectedView)} type={doc._id}>
            <AppDocView doc={doc} colDefs={colDoc} />
          </AppAssetsSectionBox>
        </Fragment>
      </Box>
    </Fragment>
  );
};

export default MemberDetail;
