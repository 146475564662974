import React, { Fragment } from "react";
import { IFilter } from "./app.filter_chips";
import { ITableFilters } from "../modules/module.interface";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Icon,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { cloneDeep, startCase } from "lodash";
import { KFormatter } from "../util/k_formatter";

interface IAppFiltersDrawer {
  filtersList: IFilter[];
  currentFilters: ITableFilters[];
  onChangeFilters: (params: ITableFilters[]) => void;
}
export const AppFiltersDrawer: React.FC<IAppFiltersDrawer> = ({
  filtersList,
  currentFilters,
  onChangeFilters,
}) => {
  const [newFiltersList, setNewFiltersList] =
    React.useState<ITableFilters[]>(currentFilters);

  const AddNewFilter = (field_name: string, value: string, title: string) => {
    const list: ITableFilters[] = cloneDeep([...newFiltersList]);
    const foundFilterIndex = list.findIndex(
      (i: any) => i.id === `${field_name}_${value}`
    );
    if (foundFilterIndex === -1) {
      list.push({
        id: `${field_name}_${value}`,
        title: title,
        operator: "is one of",
        field: field_name,
        value: value,
      });
    } else {
      list.splice(foundFilterIndex, 1);
    }

    setNewFiltersList(list);
  };

  return (
    <Fragment>
      <Stack spacing={1}>
        <Grid
          container
          justifyContent={"center"}
          columnSpacing={2}
          sx={{ paddingY: 1.5 }}
        >
          <Grid item>
            <Button
              color="error"
              size="small"
              onClick={() => setNewFiltersList([])}
              startIcon={<Icon style={{ fontSize: 16 }}>filter_alt_off</Icon>}
              variant="outlined"
            >
              <Typography
                sx={{ fontSize: 13, fontWeight: 500, textTransform: "none" }}
              >
                Clear Selected Filters
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              size="small"
              startIcon={<Icon style={{ fontSize: 16 }}>check</Icon>}
              variant="contained"
              onClick={() => {
                onChangeFilters(newFiltersList);
              }}
            >
              <Typography
                sx={{ fontSize: 13, fontWeight: 500, textTransform: "none" }}
              >
                Apply Selected Filters
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {filtersList.map((item) => (
          <Box key={item.field_name}>
            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500 }}
                  color={"secondary"}
                >
                  <span style={{ fontWeight: 600 }}>{item.title}</span> ({" "}
                  {KFormatter(item.fullData.length)} )
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider sx={{ mb: 1, mt: -2 }} />
                {item.fullData.map((filterItem) => (
                  <MenuItem disableTouchRipple disableGutters disableRipple>
                    <Stack
                      direction={"row"}
                      sx={{ paddingY: "0px", paddingRight: "8px" }}
                    >
                      <Checkbox
                        checked={newFiltersList.some(
                          (i) => i.id === `${item.field_name}_${filterItem.key}`
                        )}
                        onChange={() => {
                          AddNewFilter(
                            item.field_name,
                            filterItem.key,
                            startCase(item.field_name)
                          );
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 14,
                          alignSelf: "center",
                          fontWeight: 500,
                        }}
                      >
                        {filterItem.key}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Stack>
    </Fragment>
  );
};
