import { Skeleton, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";

interface IBarChart {
  loading: boolean;
  labels: string[];
  chartColor: string;
  chartSeries: {
    name: string;
    data: any;
  }[];
  containerHeight?: number;
}

export const StyledBarChart: React.FC<IBarChart> = ({
  loading,
  labels,
  chartColor,
  chartSeries,

  containerHeight = 300,
}) => {
  const theme = useTheme();

  const chartOptions: ApexCharts.ApexOptions = {
    labels: labels,
    dataLabels: {
      enabled: false,
      style: {
        colors: ["#ffffff"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    colors: [chartColor],
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: 0,
      dashArray: 0,
    },
    fill: {
      colors: [chartColor],
      type: "solid",
      opacity: 0.7,
    },

    yaxis: {
      labels: {
        align: "left",

        style: {
          colors: theme.palette.text.primary,
          fontFamily: "Montserrat",
          fontSize: "12",
          fontWeight: "500",
        },
      },
    },
    xaxis: {
      labels: {
        trim: true,
        style: {
          colors: theme.palette.text.primary,
          fontFamily: "Montserrat",
          fontSize: "0.6rem",
          fontWeight: 100,
        },
      },
    },
    grid: {
      show: false,
      row: {
        colors: [
          theme.palette.background.paper,
          theme.palette.background.default,
        ],
        opacity: 1,
      },
    },

    chart: {
      type: "bar",
      height: "80%",
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
      },
    },
    noData: {
      text: loading ? "Loading..." : "Data not available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 5,
      fontWeight: 10,
      labels: {
        colors: [theme.palette.text.secondary],
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      fillSeriesColor: true,
      x: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 3,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",
        columnWidth: "65%",
        barHeight: "65%",
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 5,
            offsetY: 5,
            style: {
              color: theme.palette.text.primary,
              fontFamily: "Montserrat",
              fontSize: "0.6rem",
              fontWeight: 500,
            },
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <ResponsiveContainer height={containerHeight}>
        {loading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
          />
        )}
      </ResponsiveContainer>
    </Fragment>
  );
};
