import React, { Fragment, useEffect } from "react";
import {
  IFilterCategory,
  IMasterColumnOnChange,
  IMasterColumns,
  IPart,
  ISearchField,
  ISection,
  PartStatus,
} from "../module.interface";
import {
  CreateMasterPartRequest,
  CreatePart,
  DeletePart,
  GetMasterParts,
  ListParts,
  UpdatePart,
} from "../../services/part.service";
import { Base } from "../_/_.index";
import { Box } from "@mui/material";
import { GetCarPartImageName } from "./utils/get-car-part-image-name";

const fieldDefinitions: ISection[] = [
  {
    section: "Default Image",
    fields: [
      {
        masterColumnName: "default_image",
        masterColumnTitle: "Image",
        renderType: "image",
        // immutable: true,
      },
    ],
  },
  {
    section: "Actual Images",
    fields: [
      {
        masterColumnName: "images",
        masterColumnTitle: "Images",
        renderType: "image-array",
      },
    ],
  },
  // Basic Information
  {
    section: "Basic Information",
    fields: [
      {
        masterColumnName: "member_id",
        masterColumnTitle: "Member ID",
        renderType: "number",
        required: true,
        immutable: true,
      },
      {
        masterColumnName: "member_name",
        masterColumnTitle: "Member Name",
        renderType: "text",
        required: true,
        immutable: true,
      },
    ],
  },
  // Part Information
  {
    section: "Part Information",
    fields: [
      {
        masterColumnName: "mstr_part",
        masterColumnTitle: "Master Part Name",
        renderType: "object-array",
        required: true,
        selectable: {
          labelField: "part_name",
          populateArrayCall: GetMasterParts,
          onChange: (props: IMasterColumnOnChange) => {
            const { selectedObj, handleChangeObj } = props;
            const mstr_part = { ...selectedObj };
            handleChangeObj && handleChangeObj(mstr_part);
          },
          AddSelectable: {
            fieldDelineations: [
              {
                section: "Part Name",
                fields: [
                  {
                    masterColumnName: "part_name",
                    masterColumnTitle: "part_name",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "part_name_ur",
                    masterColumnTitle: "part_name_ur",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "part_name_ar",
                    masterColumnTitle: "part_name_ar",
                    renderType: "text",
                  },
                ],
              },
            ],
            module_permission: "inventory",
            createEntity: CreateMasterPartRequest,
            initialData: {},
            titleModal: "Request New Item",
            subTitleModal: "Send us a new item request",
          },
        },
      },
      {
        masterColumnName: "part_name_ar",
        masterColumnTitle: "part_name_ar",
        renderType: "text",
      },
      {
        masterColumnName: "category_name",
        masterColumnTitle: "category_name",
        renderType: "text",
      },
      {
        masterColumnName: "category_name",
        masterColumnTitle: "category_name",
        renderType: "text",
      },
      {
        masterColumnName: "part_id",
        masterColumnTitle: "part_id",
        renderType: "text",
      },
      {
        masterColumnName: "part_name_ur",
        masterColumnTitle: "part_name_ur",
        renderType: "text",
      },
      {
        masterColumnName: "part_name",
        masterColumnTitle: "part_name",
        renderType: "text",
      },
      {
        masterColumnName: "category_name_ur",
        masterColumnTitle: "category_name_ur",
        renderType: "text",
      },
      {
        masterColumnName: "inv_part_id",
        masterColumnTitle: "SKU",
        renderType: "text",
        required: true,
      },
      {
        masterColumnName: "part_name",
        masterColumnTitle: "Part Name",
        renderType: "text",
        required: true,
      },
      {
        masterColumnName: "inv_id",
        masterColumnTitle: "Inventory ID",
        renderType: "text",
        required: true,
      },
      {
        masterColumnName: "status",
        masterColumnTitle: "Status",
        renderType: "text-array",
        required: true,
        selectable: {
          array: Object.values(PartStatus),
        },
      },
      {
        masterColumnName: "images",
        masterColumnTitle: "Images",
        renderType: "text",
        required: false,
      },
      {
        masterColumnName: "condition",
        masterColumnTitle: "Condition",
        renderType: "text",
        required: false,
      },
      {
        masterColumnName: "OEM",
        masterColumnTitle: "OEM",
        renderType: "text",
        required: false,
      },
    ],
  },
  // Compatibility
  {
    section: "Compatibility",
    fields: [
      {
        masterColumnName: "compatible_cars",
        masterColumnTitle: "Compatible Cars",
        renderType: "text-array",
        required: false,
        selectable: {
          array: ["OEM", "OEM2", "OEM3", "OEM4"],
          multiSelect: true,
        },
      },
      {
        masterColumnName: "functionality",
        masterColumnTitle: "Functionality",
        renderType: "text",
        required: false,
      },
      {
        masterColumnName: "category",
        masterColumnTitle: "Category",
        renderType: "text",
        required: false,
      },
    ],
  },
  // Make and Model Information (Autofilled by OEM)
  {
    section: "Make and Model Information",
    fields: [
      {
        masterColumnName: "make_id",
        masterColumnTitle: "Make ID",
        renderType: "number",
        required: true,
      },
      {
        masterColumnName: "make_name",
        masterColumnTitle: "Make Name",
        renderType: "text",
        required: true,
      },
      {
        masterColumnName: "model_id",
        masterColumnTitle: "Model ID",
        renderType: "number",
        required: true,
      },
      {
        masterColumnName: "model_name",
        masterColumnTitle: "Model Name",
        renderType: "text",
        required: true,
      },
    ],
  },
  // Staff Information
  {
    section: "Staff Information",
    fields: [
      {
        masterColumnName: "staff_email",
        masterColumnTitle: "Staff Email",
        renderType: "email",
        required: true,
      },
      {
        masterColumnName: "staff_name",
        masterColumnTitle: "Staff Name",
        renderType: "text",
        required: true,
      },
    ],
  },
  // Timestamps
  {
    section: "Timestamps",
    fields: [
      {
        masterColumnName: "created_at",
        masterColumnTitle: "Created At",
        renderType: "number",
        required: true,
      }, // Consider using date picker
      {
        masterColumnName: "last_update",
        masterColumnTitle: "Last Update",
        renderType: "number",
        required: true,
      }, // Consider using date picker
    ],
  },
];

const searchFields: ISearchField[] = [
  {
    fieldName: "part_name",
    icon: "construction",
    title: "Part",
    hideOptions: false,
  },
  {
    fieldName: "make_name",
    icon: "public",
    title: "Brand",
    hideOptions: false,
  },
  {
    fieldName: "model_name",
    icon: "directions_car",
    title: "Model",
    hideOptions: false,
  },
  {
    fieldName: "staff_name",
    icon: "person",
    title: "Added By Name",
    hideOptions: false,
  },
  {
    fieldName: "staff_email",
    icon: "contact_mail",
    title: "Added By Email",
    hideOptions: false,
  },
];

const filtersCategories: IFilterCategory[] = [
  {
    field_name: "part_name",
    title: "Parts",
    aggs_field_name: "PART_NAME",
  },
  {
    field_name: "make_name",
    title: "Brands",
    aggs_field_name: "MAKE_NAME",
  },
  {
    field_name: "model_name",
    title: "Models",
    aggs_field_name: "MODEL_NAME",
  },
  {
    field_name: "staff_name",
    title: "Added By",
    aggs_field_name: "STAFF_NAME",
  },
];

const tableColumns: IMasterColumns[] = [
  {
    masterColumnName: "part_name",
    masterColumnTitle: "Image",
    renderType: "image",
    cellRender: (params) => {
      return (
        <Box
          sx={{
            width: "122px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "75px",
          }}
        >
          <img
            src={
              params.default_image
                ? params.default_image
                : //TODO: remove when index is clean
                  `${GetCarPartImageName(params.part_name)}`
            }
            alt={`${params.part_name}`}
            height={"70px"}
            width={"auto"}
            style={{ maxWidth: "120px", borderRadius: "4px" }}
          />
        </Box>
      );
    },
  },
  {
    masterColumnName: "part_name",
    masterColumnTitle: "Part Name",
    renderType: "text",
  },
  {
    masterColumnName: "make_name",
    masterColumnTitle: "Car Make",
    renderType: "text",
  },
  {
    masterColumnName: "model_name",
    masterColumnTitle: "Car Model",
    renderType: "text",
  },
  {
    masterColumnName: "created_at",
    masterColumnTitle: "Created On",
    renderType: "date_time",
  },
];

const string = {
  addFab: "Add Part",
  title: "Parts Inventory",
  description:
    "An overview of the currently available parts in your inventory.",
};

const initialData = {
  inv_part_id: "",
  part_id: "",
  part_name: "",
  inv_id: "",
  status: PartStatus.Available,
  member_id: "",
  member_name: "",
  make_id: 0,
  make_name: "",
  model_id: 0,
  model_name: "",
  staff_email: "",
  staff_name: "",
  created_at: Date.now(),
  last_update: Date.now(),
  // Optional fields are either left out or set to undefined
  images: undefined,
  default_image: undefined,
  condition: undefined,
  OEM: undefined,
  compatible_cars: undefined,
  functionality: undefined,
  category: undefined,
};

export const PartsController: React.FC = () => {
  return (
    <Fragment>
      <Base<IPart>
        module_permission="inventory"
        fieldDefinitions={fieldDefinitions}
        searchFields={searchFields}
        initialData={initialData}
        filtersCategories={filtersCategories}
        listEntity={ListParts}
        updateEntity={UpdatePart}
        deleteEntity={DeletePart}
        createEntity={CreatePart}
        tableColumns={tableColumns}
        strings={string}
      />
    </Fragment>
  );
};
