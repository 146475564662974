import { IESDoc, IESQueryResponse } from "../../app.interface";
import { IMemberRequest, ITableFilters } from "../../modules/module.interface";
import axios from "../axios.service";

export const FetchRequestData = async <T>(
  request_id: number
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/requests/get", {
      request_id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const InternalGetRequestsData = async <T>(
  request_id: string,
  pageIndex?: number,
  pageSize?: number,
  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/requests/list", {
      request_id: request_id,
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const InternalCreateRequestRecord: (
  req: IMemberRequest
) => Promise<any> = async (req) => {
  try {
    const response = await axios.post("/requests/add", req);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const InternalUpdateRequestRecord = async (doc: IESDoc) => {
  try {
    console.log(doc);
    const response = await axios.post("/requests/update", {
      doc,
    });
    console.log(doc);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const InternalDeleteRequestRecord = async (docs: IESDoc[]) => {
  try {
    const response = await axios.post("/requests/delete", {
      docs,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
