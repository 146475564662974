import { has } from "lodash";

export const isLoggedIn = () => {
  const savedUserToken = JSON.parse(
    localStorage.getItem("logged_user_record") || "{}"
  );
  const tempUserToken = JSON.parse(
    sessionStorage.getItem("logged_user_record") || "{}"
  );

  if (has(savedUserToken, "staff_email") || has(tempUserToken, "staff_email")) {
    return true;
  } else {
    return false;
  }
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
};
