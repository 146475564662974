import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { PartsController } from "../part/part.index";

export const InventoryModuleController: React.FC = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/inventory" element={<PartsController />}>
          <Route path="part" element={<PartsController />} />
        </Route>
      </Routes>
    </Fragment>
  );
};

// export const InventoryModuleControllerDecrypted: React.FC = () => {
//   const TABS: {
//     title: string;
//     name: string;
//     icon: string;
//   }[] = [
//     {
//       title: "Summary",
//       name: "summary",
//       icon: "bar_chart",
//     },
//     {
//       title: "My Parts",
//       name: "my_parts",
//       icon: "handyman",
//     },
//     {
//       title: "My Cars",
//       name: "my_cars",
//       icon: "time_to_leave",
//     },
//   ];
//   const [selectedTab, setSelectedTab] = React.useState<{
//     title: string;
//     name: string;
//     icon: string;
//   }>(TABS[1]);
//   const theme = useTheme();
//   return (
//     <Fragment>
//       <TabContext value={selectedTab.name}>
//         <Tabs
//           value={selectedTab.name}
//           onChange={(event, value) => {
//             const item = TABS.find((i) => i.name === value);
//             if (item) {
//               setSelectedTab(item);
//             }
//           }}
//         >
//           {TABS.map((tab) => (
//             <Tab
//               value={tab.name}
//               key={tab.name}
//               disableTouchRipple
//               label={
//                 <Box
//                   sx={
//                     tab.name === selectedTab.name
//                       ? {
//                           paddingX: 2,
//                           paddingY: 1,
//                           borderRadius: "200px",
//                           backgroundColor: alpha(
//                             theme.palette.primary.main,
//                             0.2
//                           ),
//                           "&:hover": {
//                             borderRadius: "200px",
//                             backgroundColor: alpha(theme.palette.divider, 0.1),
//                             transition: "ease-in-out 0.25s",
//                           },
//                         }
//                       : {
//                           paddingX: 2,
//                           paddingY: 1,

//                           "&:hover": {
//                             borderRadius: "200px",
//                             backgroundColor: alpha(theme.palette.divider, 0.1),
//                             transition: "ease-in-out 0.25s",
//                           },
//                         }
//                   }
//                 >
//                   <Stack direction={"row"} spacing={1}>
//                     <Icon style={{ alignSelf: "center" }}>{tab.icon}</Icon>
//                     <Typography
//                       sx={{
//                         fontSize: 12,
//                         fontWeight: 600,
//                         alignSelf: "center",
//                       }}
//                     >
//                       {tab.title}
//                     </Typography>
//                   </Stack>
//                 </Box>
//               }
//             />
//           ))}
//         </Tabs>
//         <Divider />
//         <TabPanel value="summary">
//           <InventorySummary />
//         </TabPanel>
//         <TabPanel value="my_cars">
//           <InventoryMyCars />
//         </TabPanel>
//         <TabPanel value="my_parts">
//           <InventoryMyParts />
//         </TabPanel>
//       </TabContext>
//       <Box sx={{ mb: 7 }}></Box>
//     </Fragment>
//   );
// };
