import _ from "lodash";
import { isPermissionValid } from "../util/user-permission-validator";
import { DashboardModule } from "./dashboard/dashboard.index";
import { InventoryModuleController } from "./inventory/inventory.index";
import { IAppModules } from "./module.interface";
import { ManageUsersController } from "./settings/manage_users/manage_users.index";
import { SettingsModuleController } from "./settings/settings.index";
import { InternalRoute } from "./admin-panel/admin-panel.index";
import { InternalManageMembersController } from "./admin-panel/manage_members/manage_members.index";
import { MembershipDetails } from "./membership/membership.index";
import { InternalManageRequestsController } from "./admin-panel/manage_requests/manage_requests.index";
import { PartsController } from "./part/part.index";

export const AppModules: IAppModules[] = [
  {
    name: "dashboard",
    title: "Dashboard",
    icon: "dashboard",
    hidden: false,
    path: "/dashboard",
    isBeta: false,
    master: false,
    permission: "dashboard.view",
    controller: DashboardModule,
    subModules: [],
    isParent: false,
  },
  {
    name: "inventory",
    title: "Inventory",
    icon: "warehouse",
    hidden: false,
    path: "/inventory",
    isBeta: false,
    master: true,
    permission: "inventory.view",
    controller: InventoryModuleController,
    isParent: true,
    subModules: [
      {
        name: "part",
        title: "Parts",
        icon: "directions_car",
        hidden: false,
        path: "/inventory/part",
        isBeta: false,
        master: false,
        permission: "inventory.view",
        controller: PartsController,
        subModules: [],
        isParent: false,
      },
    ],
  },
  {
    name: "settings",
    title: "Settings",
    icon: "settings",
    hidden: false,
    path: "/settings",
    isBeta: false,
    master: true,
    permission: "settings.view",
    controller: SettingsModuleController,
    isParent: true,
    subModules: [
      {
        name: "users",
        title: "Manage Users",
        icon: "assignment_ind",
        hidden: false,
        path: "/settings/users",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: ManageUsersController,
        subModules: [],
        isParent: false,
      },
      {
        name: "membership",
        title: "Membership Detail",
        icon: "",
        hidden: false,
        path: "settings/membership",
        isBeta: false,
        master: false,
        permission: "dashboard.view", //TODO: update permission
        controller: MembershipDetails,
        subModules: [],
        isParent: false,
      },
    ],
  },
  {
    isInternal: true,
    name: "internal",
    title: "Internal",
    icon: "code",
    hidden: false,
    path: "/internal",
    isBeta: false,
    master: true,
    permission: "settings.view",
    controller: InternalRoute,
    isParent: true,
    subModules: [
      {
        isInternal: true,
        name: "internal.members",
        title: "Manage Members",
        icon: "assignment_ind",
        hidden: false,
        path: "/internal/members",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: InternalManageMembersController,
        subModules: [],
        isParent: false,
      },
      {
        isInternal: true,
        name: "requests",
        title: "Manage Requests",
        icon: "assignment_ind",
        hidden: false,
        path: "/internal/requests",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: InternalManageRequestsController,
        subModules: [],
        isParent: false,
      },
    ],
  },
];

export const receiveModules = (): IAppModules[] => {
  const resultedModules: IAppModules[] = [];

  AppModules.forEach((module) => {
    const x = _.cloneDeep(module);
    let isModuleVisible = recursivePermissionCheck(module);

    const allowedSubModules = recieveSubModules(module.subModules);
    if (module.isParent === true && allowedSubModules.length === 0) {
      isModuleVisible = false;
    }
    x.subModules = allowedSubModules;
    if (isModuleVisible === true) {
      resultedModules.push(x);
    }
  });

  return resultedModules;
};

const recieveSubModules = (subModules: IAppModules[]): IAppModules[] => {
  let resultedSubModules: IAppModules[] = [];

  subModules.forEach((subModule) => {
    if (recursivePermissionCheck(subModule)) {
      resultedSubModules.push(subModule);
    }
  });

  return resultedSubModules;
};
const recursivePermissionCheck = (module: IAppModules): boolean => {
  let isAllowed = isPermissionValid(module.permission);
  if (module.subModules.length > 0) {
    module.subModules.forEach((subModule) => {
      if (recursivePermissionCheck(subModule) === true) {
        isAllowed = true;
      }
    });
  }

  return isAllowed;
};
