import {
  Box,
  Icon,
  Paper,
  Skeleton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { range, set } from "lodash";
import React, { useEffect } from "react";

type IFieldCard = TextFieldProps & {
  title: string;
  value?: any;
  loading: boolean;
  isDefault: boolean;
  isMasked?: boolean;
  isEdit?: boolean;
  requested?: string;
};

export const FieldCard: React.FC<IFieldCard> = (IFieldCardProps) => {
  const {
    title,
    loading,
    isDefault,
    isMasked,
    isEdit,
    value,
    requested,
    ...props
  } = IFieldCardProps;
  const theme = useTheme();
  const [color, setColor] = React.useState<
    "primary" | "secondary" | "info" | "error" | "success" | "warning"
  >("primary");
  useEffect(() => {
    setColor(isEdit ? "success" : "primary");
  }, [isEdit]);

  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: isDefault
          ? theme.palette.background.default
          : theme.palette.background.paper,
        padding: 2,
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={45} />
      ) : (
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Box width={"100%"}>
            {isEdit ? (
              <TextField
                sx={{
                  maxWidth: "70%",
                }}
                label={title}
                focused
                color={color}
                size="small"
                type="Text"
                fullWidth
                value={value}
                {...props}
              />
            ) : requested && value != requested ? (
              <Stack
                width={"100%"}
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                spacing={1}
              >
                <Stack>
                  <Typography
                    color={color}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                    {value}
                  </Typography>
                </Stack>
                {/* Green Box */}
                <Stack>
                  <Typography
                    textAlign={"right"}
                    sx={{
                      fontSize: 10,
                      fontWeight: 600,
                      color: "green",
                    }}
                  >
                    This will value be..
                  </Typography>
                  {/* Green Box */}
                  <Box
                    sx={{
                      border: "2px solid green",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                    {requested}
                  </Box>
                </Stack>
              </Stack>
            ) : (
              <Stack>
                <Typography
                  color={color}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {title}
                </Typography>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  {value}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      )}
    </Paper>
  );
};
