import _ from "lodash";
import { IWritableCell } from "../../_.types";
import { Autocomplete, Button, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { toast } from "../../../../state/snackbar";
import { FullScreenModal } from "../../../../components/app.full-screen.modal";
import { isPermissionValid } from "../../../../util/user-permission-validator";
import { CreateEntity } from "../../views/_.create.modal";
import { IAddSelectable } from "../../../module.interface";

export const WritableSelectableObject: React.FC<IWritableCell> = (props) => {
  const {
    entity,
    handleChange,
    masterColumnName,
    masterColumnTitle,
    selectable,
  } = props;
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  if (!selectable) return <></>;
  const { array, labelField, onChange, AddSelectable } = selectable;
  const value = _.get(entity, masterColumnName);
  // Only matter if AddSelectable is provided
  const onClose = () => {
    setShowAddModal(false);
  };

  if (!labelField) {
    // TODO: error message
    toast("labelField is required", "error");
    return <></>;
  }

  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12} key={masterColumnName}>
        <Autocomplete
          disableClearable
          value={value || undefined}
          options={array || []}
          // TODO: validate that options[0][labelFiled] is string
          getOptionLabel={(option) => option[labelField] as string}
          renderOption={(props, option) => {
            return <li {...props}>{option[labelField] as string}</li>;
          }}
          onChange={(e, selectedObj) => {
            handleChange(selectedObj, masterColumnName);
            onChange && onChange({ selectedObj, ...props });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={masterColumnTitle}
              placeholder="Select Childs"
            />
          )}
          PaperComponent={({ children }) => {
            return (
              <Paper>
                {children}
                <Button
                  color="primary"
                  fullWidth
                  sx={{ justifyContent: "flex-start", pl: 2 }}
                  onMouseDown={() => {
                    setShowAddModal(true);
                  }}
                >
                  + Add New
                </Button>
              </Paper>
            );
          }}
        />
      </Grid>

      {AddSelectable &&
      isPermissionValid(`${AddSelectable.module_permission}.add`) ? (
        <CreateEntityModal
          {...AddSelectable}
          showAddModal={showAddModal}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

type IAddSelectableView = IAddSelectable & {
  showAddModal: boolean;
  onClose: () => void;
};
const CreateEntityModal: React.FC<IAddSelectableView> = (props) => {
  const {
    fieldDelineations,
    initialData,
    createEntity,
    onClose,
    titleModal,
    subTitleModal,
    showAddModal,
  } = props;
  return (
    <>
      <FullScreenModal
        isOpen={showAddModal}
        onClose={onClose}
        width="lg"
        title={titleModal}
        subTitle={subTitleModal}
        content={
          <CreateEntity<any>
            onClose={onClose}
            fieldDelineations={fieldDelineations}
            initialData={initialData}
            createEntity={createEntity}
            appendMemberDetailsToEntity={false}
          />
        }
      />
    </>
  );
};
