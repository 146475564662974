import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Stack,
  TextField,
} from "@mui/material";
import React, { Fragment } from "react";
import { toast } from "../state/snackbar";
import { has } from "lodash";
import { ValidateEmail, ValidatePhone } from "../util/validator";
import {
  IMemberCreateRequest,
  IMemberRequest,
} from "../modules/module.interface";
import { CreateRequestRecord } from "../services/request.service";

interface IRegisterForm {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegisterForm: React.FC<IRegisterForm> = ({ setIsLogin }) => {
  const [contactNumber, setContactNumber] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const handelSubmit = () => {
    // TODO: figure out where to wrap this data preparation
    const value: IMemberCreateRequest = {
      contact_number: contactNumber,
      member_name: name,
      email: userEmail,
    };
    const requestRecord: IMemberRequest = {
      action: "Create",
      module: "member",
      value,
      created_by: userEmail,
    };

    setLoading(true);
    CreateRequestRecord(requestRecord)
      .then((res) => {
        toast(
          "We received your request, will follow up with you soon.",
          "success"
        );
        setIsLogin(true);
      })
      .catch(() => {
        toast(
          "Error while sending your request. Please try again or contact us directly",
          "error"
        ); //TODO: better Error Messages
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const keyDownAction = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter" && name !== "" && userEmail !== "")
      return handelSubmit();
    else return null;
  };

  React.useEffect(() => {
    const savedUserToken = JSON.parse(
      localStorage.getItem("logged_user_record") || "{}"
    );
    const tempUserToken = JSON.parse(
      sessionStorage.getItem("logged_user_record") || "{}"
    );
    if (
      has(savedUserToken, "staff_email") ||
      has(tempUserToken, "staff_email")
    ) {
      window.location.replace("/");
    }
  }, []);

  return (
    <Fragment>
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          {/* Contact Number */}
          <TextField
            size="small"
            value={contactNumber}
            label="Contact Number"
            type="number"
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
            fullWidth
            sx={{ minWidth: "100%", fontSize: 13, fontWeight: 600 }}
            error={
              contactNumber.length > 0
                ? !ValidatePhone(contactNumber)
                : undefined
            }
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <Icon color="primary">phone</Icon>
                </React.Fragment>
              ),
            }}
            onKeyDown={keyDownAction}
          />

          {/* Name */}
          <TextField
            size="small"
            value={name}
            label="Business Name"
            type="text"
            onChange={(e) => {
              setName(e.target.value);
            }}
            fullWidth
            sx={{ minWidth: "100%", fontSize: 13, fontWeight: 600 }}
            error={undefined} //TODO: Validate Name
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <Icon color="primary">business</Icon>
                </React.Fragment>
              ),
            }}
            onKeyDown={keyDownAction}
          />

          {/* Email */}
          <TextField
            size="small"
            value={userEmail}
            label="Email"
            type="email"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            fullWidth
            sx={{ minWidth: "100%", fontSize: 13, fontWeight: 600 }}
            error={userEmail.length > 0 ? !ValidateEmail(userEmail) : undefined}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <Icon color="primary">email</Icon>
                </React.Fragment>
              ),
            }}
            onKeyDown={keyDownAction}
          />

          <Grid container justifyContent={"space-between"} rowSpacing={1}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              sx={{ paddingLeft: "0px !important", mb: 6 }}
            >
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  handelSubmit();
                }}
                disabled={
                  contactNumber === "" || name === "" || userEmail === ""
                }
                sx={{ minWidth: "100%" }}
              >
                Register
              </LoadingButton>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignSelf={"center"}
            sx={{ paddingLeft: "0px !important", textAlign: "center" }}
          >
            <Button
              color="secondary"
              size="medium"
              onClick={() => {
                setIsLogin(true);
              }}
            >
              Back To Login
            </Button>
          </Grid>
        </Stack>
      </Box>
    </Fragment>
  );
};
