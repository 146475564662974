import validator from "validator";
export const ValidateEmail = (input: string): boolean => {
  return validator.isEmail(input);
};
export const ValidateURL = (input: string): boolean => {
  return validator.isURL(input);
};
export const ValidateDomain = (input: string): boolean => {
  return validator.isFQDN(input);
};
export const ValidateIP = (input: string): boolean => {
  return validator.isIP(input);
};
export const ValidatePhone = (input: string): boolean => {
  return validator.isMobilePhone(input);
};
export const ValidateAccountNumber = (input: string): boolean => {
  const regex = /^[^.](?!.*[.]{2})[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]{2,}[^.]+$/;
  if (input.match(regex)) return true;
  else return false;
};
export const ValidateURLWithProtocols = (input: string): boolean => {
  return validator.isURL(input, {
    require_protocol: true,
    protocols: ["http", "https", "ftp", "skype"],
  });
};

export const ValidateUserID = (input: string) => {
  const regex = /[A-Za-z]+(?:.)+(\/{1})+.+/gim;
  if (input.match(regex)) return true;
  else return false;
};
export const ValidateRegex = (input: string): boolean => {
  let isValid = true;
  try {
    new RegExp(input);
  } catch (error) {
    isValid = false;
  }
  return isValid;
};
export const ValidateAny = (input: string): boolean => {
  const regex = /([0-9a-z])\w+/gi;
  if (input.match(regex)) return true;
  else return false;
};
