import { Box, Button, Grid, Icon, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { IWritableCell } from "../../_.types";

export const WritableImageCell: React.FC<
  IWritableCell & { initialImagePath?: string }
> = ({
  initialImagePath,
  handleChange,
  masterColumnName,
  immutable = false,
}) => {
  const imageNotFound = "/images/image-not-found.png";
  // TODO: if image is wrong/not found
  // TODO: remap all entities to have a default image
  // TODO: add a default image
  // this will not be used if there is a preview
  const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result as string);
        handleChange(reader.result as string, masterColumnName);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleDeleteFile = () => {
    setFile(undefined);
    setImageBase64(undefined);
    handleChange("", masterColumnName);
  };

  return (
    <>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        key={masterColumnName}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{
            width: "32rem",
            maxHeight: "280px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            // overflow: "hidden",
          }}
        >
          {/* {initialImagePath} */}
          <img
            src={
              imageBase64
                ? imageBase64
                : initialImagePath
                ? initialImagePath
                : imageNotFound
            }
            alt="Uploaded Preview"
            width={"auto"}
            style={{
              objectFit: "contain",
              maxHeight: "230px",
              maxWidth: "100%",
              marginBottom: "1rem",
              borderRadius: "4px",
            }}
          />
          {!immutable && (
            <Grid gap={1} container>
              <Grid
                item
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange} // Handle file change
                  />
                </Button>
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                textOverflow={"clip"}
              >
                {file && (
                  <>
                    <Typography
                      noWrap
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%", // Ensure it doesn't overflow the grid
                      }}
                    >
                      {file.name}
                    </Typography>
                    <IconButton onClick={handleDeleteFile} size="large">
                      <Icon>close</Icon>
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
};
