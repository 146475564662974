import axios from "./axios.service";

export const GetTopEmployeeInventory = async (
  member_id: string
): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/car-count-by-employee", {
      member_id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const GetTopMakesInventory = async (): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/car-count-by-make");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const GetInventoryGrowthOvertime = async (): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/car-count-overtime");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
