import moment from "moment";
import { IDateFilterObj } from "../modules/module.interface";
import { isEmpty } from "lodash";

export const setInitialDateRanges = () => {
  const dateHistoryObj: IDateFilterObj[] = [
    {
      status: false,
      startDate: 0,
      endDate: moment(new Date()).valueOf(),
      mode: "quick",
      value: "All Time",
    },
  ];
  let currentDateHistory: IDateFilterObj[] = JSON.parse(
    sessionStorage.getItem(
      `${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`
    ) || JSON.stringify(dateHistoryObj)
  );
  if (isEmpty(currentDateHistory)) {
    currentDateHistory = dateHistoryObj;
  }
  if (!isEmpty(currentDateHistory)) {
    if (currentDateHistory[currentDateHistory.length - 1].mode === "quick") {
      currentDateHistory[currentDateHistory.length - 1].endDate = moment(
        new Date()
      ).valueOf();
    }
  }
  sessionStorage.setItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`,
    JSON.stringify(currentDateHistory)
  );
  return currentDateHistory[currentDateHistory.length - 1];
};
